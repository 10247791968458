import { AMOUNT_TYPE, ANALYTICS_EVENT, CLASSES, ENVIRONMENTS, LOG_LEVEL, URL_PARAMS } from 'src/constants'

import { Environment, Options, OptionsProps, Status } from 'src/config'

import { Checkout, CheckoutInputAttributesProps } from 'src/classes/checkout'

import {
  addAnimationStylesheet,
  addButtonStylesheet,
  addUserToAudience,
  affiliateId,
  affiliateToken,
  analyticsEvent,
  audiencePopup,
  audienceSubscribe,
  campaignAttributes,
  getButtonAttributes,
  getProductPrice,
  getProductPrices,
  getURLFromID,
  hideLoading,
  isAffiliate,
  isMobile,
  linkId,
  listen,
  loadButtons,
  loadDownloads,
  logger,
  orderDetails,
  orderDetailsPopup,
  polyfills,
  ready,
  sellerId,
  showLoading,
  startDownload,
  urlParam,
  userHistory,
  isTruthy,
  allowAudiencePopup,
  logAudiencePopup,
  GetProductPricesCallbackProps,
  GetProductPriceCallbackProps,
  UserHistoryCallbackProps,
  UserAttributeProps,
  SubscribeAudienceCallbackProps,
  OrderCallbackProps,
} from 'src/utils'
import { PopupAttributesProps } from 'src/utils/audience'

export class Paddle {
  Checkout: {
    open: (options: CheckoutInputAttributesProps) => void
  }
  Environment: {
    detect: () => void
    get: () => ENVIRONMENTS
    set: (environment: ENVIRONMENTS) => void
  }
  Audience: {
    Popup: (inputAttributes: PopupAttributesProps) => string
    subscribe: (
      email: string,
      marketingConsent: SubscribeAudienceCallbackProps | boolean,
      callback: SubscribeAudienceCallbackProps,
    ) => void
    AllowPopup: () => boolean
    LogPopup: () => void
    addUserToAudience: (userAttributes: UserAttributeProps, callback: SubscribeAudienceCallbackProps) => void
  }
  Affiliate: {
    affiliateId: () => string | false
    affiliateToken: () => string | boolean
    isAffiliate: () => boolean
    linkId: () => string | false
    sellerId: () => string | false
  }
  Spinner: {
    show: () => string | void
    hide: () => void
  }
  User: { History: (email: string, product_id: string | null, callback?: UserHistoryCallbackProps) => void }
  Status: {
    libraryVersion: string
  }
  Product: {
    Prices: (
      productId: string,
      quantity: GetProductPricesCallbackProps | number,
      callback?: GetProductPricesCallbackProps,
    ) => void
    Price: (
      amountType: AMOUNT_TYPE | undefined,
      productId: string,
      quantity: number,
      callback: GetProductPriceCallbackProps,
    ) => void
  }
  Download: {
    GetURLFromID: (product_id: string) => string | boolean
    Start: (url: string | boolean) => void
  }
  Order: {
    DetailsPopup: (checkoutHash: string, processingMessage?: string) => void
    details: (checkoutHash: string, callback?: OrderCallbackProps, showLoader?: boolean) => void
  }

  constructor(version: string) {
    Status.libraryVersion = version

    // Prototype Polyfills
    polyfills()

    window._hthck = 1

    // Public methods
    this.Checkout = {
      open: (options: CheckoutInputAttributesProps) => Checkout.open(options),
    }

    this.Environment = {
      detect: () => Environment.detect(),
      get: () => Environment.get(),
      set: (environment) => Environment.set(environment),
    }

    this.Audience = {
      Popup: audiencePopup,
      subscribe: audienceSubscribe,
      AllowPopup: allowAudiencePopup,
      LogPopup: logAudiencePopup,
      addUserToAudience: addUserToAudience,
    }

    this.Affiliate = {
      affiliateId: affiliateId,
      affiliateToken: affiliateToken,
      isAffiliate: isAffiliate,
      linkId: linkId,
      sellerId: sellerId,
    }

    // Public methods for working with loading spinners.
    this.Spinner = {
      show: showLoading,
      hide: hideLoading,
    }

    this.User = {
      History: userHistory,
    }

    this.Status = {
      libraryVersion: Status.libraryVersion,
    }

    this.Product = {
      Prices: getProductPrices,
      Price: getProductPrice,
    }

    this.Download = {
      GetURLFromID: getURLFromID,
      Start: startDownload,
    }

    this.Order = {
      DetailsPopup: orderDetailsPopup,
      details: orderDetails,
    }
  }

  Options(options: OptionsProps) {
    Options.set(options)
  }

  // Setup is called once per session, here we do anything global, like setting listeners...
  Setup(options: OptionsProps) {
    if (!Status.completedSetup) {
      // If an 'options' object is passed to Setup, then use it.
      // if (typeof options != 'undefined' && typeof options == 'object') {
      if (typeof options.vendor != 'undefined') {
        Options.set(options)
      } else {
        throw new Error(
          '[PADDLE CLASSIC] You must specify your Paddle Vendor ID within the Paddle.Setup() method. See: https://developer.paddle.com/guides/how-tos/checkout/paddle-checkout',
        )
      }
      // Track Affiliate visits.
      analyticsEvent(ANALYTICS_EVENT.VISIT)
      // // Load buttons & animations stylesheets.
      addAnimationStylesheet()
      addButtonStylesheet()
      // // Check mobile session...
      if (isMobile()) {
        logger.log('[PADDLE CLASSIC] Mobile session detected.')
      }
      // // Here we would look for 'paddle_button' items, and call a function to iterate
      // // over them, like this.Paddle.Button.load(); ('this' is required due to our current scope)
      loadButtons()
      // // Here we look for 'paddle_download' items, and invoke them.
      loadDownloads()
      // // Mark the library as having completed setup, so we don't run this block more than once
      // // even if there are multipe <script>'s with Paddle.js on the page.
      Status.completedSetup = true
      // // Fire a 'completed setup' debug event, just for verbosity.
      logger.log('[PADDLE CLASSIC] Completed library setup.')
      // // Start listeners.
      listen()
      // // Detect/set checkout environment.
      Environment.detect()
      // // Detect and store campaign attributes (and affiliates)
      campaignAttributes()
      // // Detect any auto-apply/auto-open URL parameters.
      detectAutoOpen()
    } else {
      logger.log(
        '[PADDLE CLASSIC] Cannot call Paddle.Setup() more than once per page, the call was ignored.',
        LOG_LEVEL.WARNING,
      )
    }
  }
}

// Auto-open the checkout if paddle_open=true is set
function detectAutoOpen() {
  if (typeof urlParam(URL_PARAMS.PADDLE_OPEN) != 'undefined' && isTruthy(urlParam(URL_PARAMS.PADDLE_OPEN))) {
    ready(_onReady)
  }
}

export const _onReady = () => {
  const firstButton: HTMLButtonElement | boolean =
    typeof document.getElementsByClassName(CLASSES.PADDLE_BUTTON)[0] != 'undefined'
      ? (document.getElementsByClassName(CLASSES.PADDLE_BUTTON)[0] as HTMLButtonElement)
      : false

  if (firstButton) {
    const buttonAttributes = getButtonAttributes(firstButton as HTMLButtonElement) as CheckoutInputAttributesProps
    Checkout.open(buttonAttributes)
  }
}
