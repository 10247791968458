import { AMOUNT_TYPE } from 'src/constants'

import { getPrices, PriceDataProps } from 'src/utils'

export interface GetProductPriceCallbackProps {
  (amount: boolean | string): void
}

// Gets a money attribute (total, unit price, tax etc...) for a product.
export function getProductPrice(
  amountType: AMOUNT_TYPE | undefined = undefined,
  productId: string,
  quantity: number = -1,
  callback: GetProductPriceCallbackProps | undefined = undefined,
) {
  let sanitisedQuantity = quantity
  if (quantity <= 0) {
    sanitisedQuantity = 1
  }

  getPrices(productId, sanitisedQuantity, _getProductPriceCallback(callback, amountType))
}

export const _getProductPriceCallback =
  (callback: GetProductPriceCallbackProps | undefined, amountType: AMOUNT_TYPE | undefined) =>
  (priceData: PriceDataProps) => {
    if (typeof callback != 'undefined') {
      if (typeof priceData.price != 'undefined') {
        if (amountType === AMOUNT_TYPE.GROSS) {
          callback(priceData.price.gross || false)
        } else if (amountType === AMOUNT_TYPE.TAX) {
          callback(priceData.price.tax || false)
        } else if (amountType === AMOUNT_TYPE.NET) {
          callback(priceData.price.net || false)
        } else if (amountType === AMOUNT_TYPE.TAX_INCLUDED) {
          callback(priceData.price.tax_included || false)
        } else {
          callback(false)
        }
      } else {
        callback(false)
      }
    }
  }

export interface GetProductPricesCallbackProps {
  (arg: PriceDataProps): void
}

// Gets all money attributes for a product
export function getProductPrices(
  productId: string,
  quantity: GetProductPricesCallbackProps | number,
  callback: GetProductPricesCallbackProps = () => {},
) {
  // The 'quantity' parameter was added later in the scope of this function. The checking here
  // allows for a user to specify (productId, callback) - in which case we treat quantity as 1, or
  // allows them to specify (productId, quantity, callback) - in which case it respects the quantity
  // parameter's value.
  let sanitizedCallback = callback
  let sanitizedQuantity = quantity
  if (typeof quantity == 'function') {
    sanitizedCallback = quantity
    sanitizedQuantity = 1
  }

  getPrices(productId, sanitizedQuantity as number, _getProductPricesCallback(sanitizedCallback))
}

export const _getProductPricesCallback =
  (sanitizedCallback?: GetProductPricesCallbackProps) =>
  (priceData: PriceDataProps = {}) => {
    if (typeof sanitizedCallback != 'undefined' && typeof sanitizedCallback === 'function') {
      sanitizedCallback(priceData)
    }
  }
