// Generic popup/modal methods
import { LOG_LEVEL, POPUP_TYPE, POPUP_METHOD, CLASSES } from 'src/constants'

import { logger, addClass, removeClass, show, hide, allowAudiencePopup, logAudiencePopup } from 'src/utils'

export function showPopup(popupId: string | number, method: POPUP_METHOD, type: POPUP_TYPE) {
  type = type || POPUP_TYPE.POPUP
  method = method || POPUP_METHOD.MANUAL

  // Only allow the popup if the user hasn't seen one recently (or it's user invoked).
  if (
    method === POPUP_METHOD.MANUAL ||
    method === POPUP_METHOD.DOWNLOAD ||
    method === POPUP_METHOD.ORDER ||
    allowAudiencePopup()
  ) {
    logger.log('Popup triggered. (Method: ' + method + ' | Type: ' + type + ')')

    const popup = document.getElementsByClassName(CLASSES.PADDLE_POPUP_INSTANCE_ID + popupId)[0] || false
    if (popup) {
      if (method !== POPUP_METHOD.MANUAL && method !== POPUP_METHOD.DOWNLOAD && method !== POPUP_METHOD.ORDER) {
        logAudiencePopup()
      }
      show(popup)
    } else {
      logger.log('Popup trigger ignored, user has seen a popup recently.', LOG_LEVEL.WARNING)
    }
  }
}

export function hidePopup(popupId: string, type = POPUP_TYPE.POPUP) {
  const popup = document.getElementsByClassName(CLASSES.PADDLE_POPUP_INSTANCE_ID + popupId)[0] || false
  const popupBox =
    document
      .getElementsByClassName(CLASSES.PADDLE_POPUP_INSTANCE_ID + popupId)[0]
      .getElementsByClassName(CLASSES.PADDLE_POPUP)[0] || false

  if (popup && popupBox) {
    logger.log('Popup dismissed. (Type: ' + type + ')')

    addClass(popupBox, CLASSES.PADDLE_FADE_OUT_UP_BIG)
    addClass(popup, CLASSES.PADDLE_FADE_OUT)

    setTimeout(function () {
      removeClass(popup, CLASSES.PADDLE_FADE_OUT)
      removeClass(popupBox, CLASSES.PADDLE_FADE_OUT_UP_BIG)
      hide(popup)
    }, 600)
  }
}
