import { ANALYTICS_EVENT, LOG_LEVEL } from 'src/constants'

import { Status, Environment } from 'src/config'

import { isAffiliate, affiliateToken, campaignAttributes, logger } from 'src/utils'
import { CampaignMeta } from 'src/utils/campaign.type'

export interface AffiliateDataType {
  token?: string | false
  link?: string | false
  affiliate?: string | false
  seller?: string | false
}

function loadPaddleAffiliateAnalytics(t: any) {
  if (!t.__SV) {
    window.paddleAffiliateAnalytics = t

    t.init = function (e: Record<string, any>, o: any) {
      t.writeKey = e
      t._initOptions = o
      t._execQueue = []
      let m =
        'action.track action.trackSale action.trackHTMLLink action.setGlobalProperty user.profile user.identify user.clear'.split(
          ' ',
        )
      for (let n = 0; n < m.length; n++) {
        ;(function () {
          var r = m[n]
          var s = function () {
            t._execQueue.push({ m: r, args: arguments })
          }
          var i = r.split('.')
          if (i.length === 2) {
            if (!t[i[0]]) {
              t[i[0]] = []
            }
            t[i[0]][i[1]] = s
          } else {
            t[r] = s
          }
        })()
      }
    }
    t.__SV = 1
  }
}

export function analyticsStart() {
  if (!Status.failedLoadingAffiliateAnalytics) {
    if (!Status.loadedAffiliateAnalytics) {
      loadPaddleAffiliateAnalytics(window.paddleAffiliateAnalytics || {})
      try {
        window.paddleAffiliateAnalytics.init(Environment.defaults().affiliateAnalyticsKey, {})
      } catch (error) {
        Status.failedLoadingAffiliateAnalytics = true
        logger.log(
          'Failed to start affiliate analytics with key: ' + Environment.defaults().affiliateAnalyticsKey,
          LOG_LEVEL.WARNING,
        )
      }
      if (!Status.failedLoadingAffiliateAnalytics) {
        Status.loadedAffiliateAnalytics = true
        logger.log('Affiliate Analytics Started')
      }
    }
  } else {
    logger.log(
      "Won't attempt to initiate affiliate analytics after previous failure in same session.",
      LOG_LEVEL.WARNING,
    )
  }
}

export function endAnalyticsSession() {
  logger.log('Ending analytics session due to conversion taking place.')
}

export function analyticsEvent(event: ANALYTICS_EVENT) {
  if (isAffiliate()) {
    if (!Status.loadedAffiliateAnalytics) {
      analyticsStart()

      window.paddleAffiliateAnalytics.user.identify(_affiliateToken() || false)
    }
  } else {
    logger.log('Ignoring "' + event + '" as this isn\'t an affiliate visit.', LOG_LEVEL.WARNING)
  }
}

function _affiliateToken(): string | boolean {
  if (isAffiliate()) {
    return affiliateToken()
  } else {
    return false
  }
}

export function linkId(): string | false {
  var campaignData: CampaignMeta = campaignAttributes()
  return campaignData?.AffiliateData?.link || false
}

export function affiliateId(): string | false {
  var campaignData: CampaignMeta = campaignAttributes()
  return campaignData?.AffiliateData?.affiliate || false
}

export function sellerId(): string | false {
  var campaignData: CampaignMeta = campaignAttributes()
  return campaignData?.AffiliateData?.seller || false
}
