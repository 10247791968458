import { CHECKOUT_METHOD, CLASSES, DATA_ATTRIBUTES, DISPLAY_MODE, IDS } from 'src/constants'

import { Options } from 'src/config'

import { addClass } from 'src/utils'
import { loadButtons, closeCheckout } from 'src/utils'

export const isWideOverlayFn = () => window._activeCheckout?.method === CHECKOUT_METHOD.WIDE_OVERLAY

// Creates the CSS for the upsell widget.
function css(leftPosition = '-300px', topPosition?: string) {
  const isWideOverlay = isWideOverlayFn()
  const perLayoutStyles = isWideOverlay ? `border-radius: 0.5rem;` : `padding: 17px; border-radius: 3px; width: 265px;`
  return (
    `position: fixed; z-index: 21474836479; top: ${
      isWideOverlay || topPosition ? topPosition || '152px' : '140px'
    }; left: ` +
    leftPosition +
    `; ${perLayoutStyles} box-shadow: 0px 1px 4px 1px rgba(0,0,0,0.13); box-sizing: content-box;`
  )
}

// Creates the HTML div for the upsell widget from product data.
export function createUpsell(
  productId?: string,
  imageUrl: string | undefined = undefined,
  title: string | undefined = undefined,
  description: string | undefined = undefined,
  ctaText: string = 'Buy Now!',
  orginalCheckoutFunction: () => void = () => {},
  passthrough: string = '',
  upsellCoupon: string | undefined = undefined,
  success: string | undefined = undefined,
) {
  window.UpsellPosition = -350
  const isWideOverlay = isWideOverlayFn()

  const body = document.getElementsByTagName('body')[0]
  const upsell = document.createElement('div')
  upsell.setAttribute('id', IDS.PADDLE_UPSELL_ID + productId)
  upsell.setAttribute(
    'class',
    isWideOverlay ? `${CLASSES.PADDLE_UPSELL} ${CLASSES.PADDLE_WIDE_OVERLAY_UPSELL}` : CLASSES.PADDLE_UPSELL,
  )
  addClass(
    upsell,
    window._activeCheckout.displayModeTheme === 'dark'
      ? CLASSES.PADDLE_UPSELL_THEME_DARK
      : CLASSES.PADDLE_UPSELL_THEME_LIGHT,
  )
  upsell.setAttribute('style', css())

  // We need to add the success callback function to the global scope so we can pass it around to the upsell checkout via Paddle button.
  window['paddleSuccessCallback'] = window._activeCheckout.successCallback || null
  window['paddleCloseCallback'] = window._activeCheckout.closeCallback || null

  const checkoutMethod = isWideOverlay ? DISPLAY_MODE.WIDE_OVERLAY : window._activeCheckout.method || ''

  const UPSELL_DIALOG_CLASSES = {
    WRAPPER: isWideOverlay ? CLASSES.PADDLE_UPSELL_WIDE_OVERLAY_WRAPPER : CLASSES.PADDLE_UPSELL_WRAPPER,
    ICON: isWideOverlay ? CLASSES.PADDLE_UPSELL_WIDE_OVERLAY_ICON : CLASSES.PADDLE_UPSELL_ICON,
    DATA: isWideOverlay ? CLASSES.PADDLE_UPSELL_WIDE_OVERLAY_DATA : CLASSES.PADDLE_UPSELL_DATA,
    TITLE: isWideOverlay ? CLASSES.PADDLE_UPSELL_WIDE_OVERLAY_TITLE : CLASSES.PADDLE_UPSELL_TITLE,
    TEXT: isWideOverlay ? CLASSES.PADDLE_UPSELL_WIDE_OVERLAY_TEXT : CLASSES.PADDLE_UPSELL_TEXT,
    CTA: isWideOverlay ? CLASSES.PADDLE_UPSELL_WIDE_OVERLAY_CTA : CLASSES.PADDLE_UPSELL_CTA,
  }

  // Checkbox vs Button Upsell
  let upsellAction
  if (Options.upsellCheckbox) {
    window.upsellType = 'Checkbox'
    upsellAction =
      `<div class="${CLASSES.PADDLE_UPSELL_CTA_CHECKBOX_CONTAINER}"><div class="${UPSELL_DIALOG_CLASSES.CTA} ${CLASSES.PADDLE_UPSELL_CTA_CHECKBOX}"><input type="checkbox" class="${CLASSES.PADDLE_UPSELL_CHECKBOX}" id="${IDS.PADDLE_UPSELL_CHECKBOX}" onchange="javascript:document.getElementsByClassName(CLASSES.PADDLE_UPSELL_BUTTON)[0].click();" /> <label for="paddle_upsell-checkbox">` +
      ctaText +
      `</label><a href="#!" class="${CLASSES.PADDLE_BUTTON} ${CLASSES.PADDLE_UPSELL_BUTTON}" style="visibility:none;" ${DATA_ATTRIBUTES.DATA_THEME}="none" ${DATA_ATTRIBUTES.DATA_DISPLAY_MODE_THEME}="` +
      (window._activeCheckout.displayModeTheme || '') +
      `" ${DATA_ATTRIBUTES.DATA_PRODUCT}="` +
      productId +
      `" ${DATA_ATTRIBUTES.DATA_METHOD}="` +
      checkoutMethod +
      `" ${DATA_ATTRIBUTES.DATA_UPSELL_BUTTON}="true" ${DATA_ATTRIBUTES.DATA_REFERRER}="Upsell" ${DATA_ATTRIBUTES.DATA_PASSTHROUGH}="` +
      encodeURIComponent(passthrough) +
      `" ${DATA_ATTRIBUTES.DATA_SUCCESS}="` +
      success +
      `" ${DATA_ATTRIBUTES.DATA_CLOSE_CALLBACK}="paddleCloseCallback" ${DATA_ATTRIBUTES.DATA_SUCCESS_CALLBACK}="paddleSuccessCallback" ${DATA_ATTRIBUTES.DATA_COUPON}="` +
      upsellCoupon +
      `" ${DATA_ATTRIBUTES.DATA_MARKETING_CONSENT}="` +
      (window._activeCheckout.marketingConsent || '') +
      `" ${DATA_ATTRIBUTES.DATA_EMAIL}="` +
      (window._activeCheckout.email || '') +
      `" ${DATA_ATTRIBUTES.DATA_COUNTRY}="` +
      (window._activeCheckout.country || '') +
      `" ${DATA_ATTRIBUTES.DATA_POSTCODE}="` +
      (window._activeCheckout.postcode || '') +
      `" ${DATA_ATTRIBUTES.DATA_LOCALE}="` +
      (window._activeCheckout.locale || '') +
      '"></a></div></div>'
  } else {
    window.upsellType = 'Button'
    upsellAction =
      `<div class="${UPSELL_DIALOG_CLASSES.CTA}"><a href="#" class="${CLASSES.PADDLE_UPSELL_BUTTON} ${CLASSES.PADDLE_BUTTON}" id="${IDS.PADDLE_UPSELL_CTA}" ${DATA_ATTRIBUTES.DATA_PRODUCT}="` +
      productId +
      `" ${DATA_ATTRIBUTES.DATA_METHOD}="` +
      checkoutMethod +
      `" ${DATA_ATTRIBUTES.DATA_DISPLAY_MODE_THEME}="` +
      (window._activeCheckout.displayModeTheme || '') +
      `" ${DATA_ATTRIBUTES.DATA_UPSELL_BUTTON}="true" ${DATA_ATTRIBUTES.DATA_REFERRER}="Upsell" ${DATA_ATTRIBUTES.DATA_PASSTHROUGH}="` +
      encodeURIComponent(passthrough) +
      `" ${DATA_ATTRIBUTES.DATA_SUCCESS}="` +
      success +
      `" ${DATA_ATTRIBUTES.DATA_CLOSE_CALLBACK}="paddleCloseCallback" ${DATA_ATTRIBUTES.DATA_SUCCESS_CALLBACK}="paddleSuccessCallback" ${DATA_ATTRIBUTES.DATA_COUPON}="` +
      upsellCoupon +
      `" ${DATA_ATTRIBUTES.DATA_MARKETING_CONSENT}="` +
      (window._activeCheckout.marketingConsent || '') +
      `" ${DATA_ATTRIBUTES.DATA_EMAIL}="` +
      (window._activeCheckout.email || '') +
      `" ${DATA_ATTRIBUTES.DATA_COUNTRY}="` +
      (window._activeCheckout.country || '') +
      `" ${DATA_ATTRIBUTES.DATA_POSTCODE}="` +
      (window._activeCheckout.postcode || '') +
      `" ${DATA_ATTRIBUTES.DATA_LOCALE}="` +
      (window._activeCheckout.locale || '') +
      '">' +
      ctaText +
      '</a></div>'
  }

  upsell.innerHTML = `<div class="${UPSELL_DIALOG_CLASSES.WRAPPER}"><div class="${UPSELL_DIALOG_CLASSES.ICON}" style="background-image: url('${imageUrl}');"></div><div class="${UPSELL_DIALOG_CLASSES.DATA}"><div class="${UPSELL_DIALOG_CLASSES.TITLE}">${title}</div><div class="${UPSELL_DIALOG_CLASSES.TEXT}">${description}</div>${upsellAction}</div></div>`
  body.appendChild(upsell)

  const original = document.createElement('div')
  original.setAttribute('id', IDS.PADDLE_UPSELL_ORIGINAL)
  original.setAttribute('class', CLASSES.PADDLE_UPSELL_ORIGINAL)
  original.setAttribute('style', 'display:none;')
  original.innerHTML = `<span class="${CLASSES.PADDLE_UPSELL_ORIGINAL_LINK}">&lsaquo; Back to Original Checkout</span>`
  original.onclick = function () {
    closeCheckout({})
    displayUpsell(`${productId}`)
    orginalCheckoutFunction()
  }
  body.appendChild(original)

  loadButtons()
}

// Slides in the specified product upsell.
export function displayUpsell(
  productId: string | number,
  useAsWOExperiment?: boolean,
  topPos?: string,
  ctaBgColor?: string,
): void {
  const useAsWideOverlayExp = useAsWOExperiment ?? window._cfeProps?.useAsWideOverlayExperiment
  const fromTop = topPos ?? window._cfeProps?.from_top
  const ctaBackgroundColor = ctaBgColor ?? window._cfeProps?.cta_background_color
  const upsell: HTMLElement | null = document.getElementById(IDS.PADDLE_UPSELL_ID + productId) as HTMLElement
  if (upsell) {
    const isWideOverlay = useAsWideOverlayExp ?? isWideOverlayFn()
    upsell.setAttribute(
      'style',
      css(
        isWideOverlay || (useAsWideOverlayExp === false && Boolean(ctaBackgroundColor))
          ? 'calc(50% - 610px)'
          : 'calc(50% + 245px)',
        fromTop,
      ),
    )

    if (Boolean(ctaBackgroundColor)) {
      const upsellCTA: HTMLElement | null = document.getElementById(IDS.PADDLE_UPSELL_CTA) as HTMLElement

      upsellCTA.setAttribute(
        'style',
        `background-color:${ctaBackgroundColor};background:${ctaBackgroundColor};border-color:${ctaBackgroundColor};`,
      )
    }

    if (useAsWideOverlayExp) {
      // add class to upsell dialog to indicate wide overlay experiment
      // and apply style adjustments
      addClass(upsell, 'experiment-as-wide-overlay')
    }
  }
}

export function hideUpsell(productId: string | number): void {
  const upsell: HTMLElement | null = document.getElementById(IDS.PADDLE_UPSELL_ID + productId) as HTMLElement
  if (upsell) {
    upsell.setAttribute('style', 'display: none')
  }
}

export function removeUpsells() {
  const upsell: HTMLElement | null = document.getElementsByClassName('paddle_upsell')[0] as HTMLElement
  const upsellOriginal: HTMLElement | null = document.getElementsByClassName('paddle_upsell_original')[0] as HTMLElement
  if (upsell) {
    const parent = upsell.parentNode
    parent?.removeChild(upsell)
  }
  if (upsellOriginal) {
    const parent = upsellOriginal.parentNode
    parent?.removeChild(upsellOriginal)
  }
}
