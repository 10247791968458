// Mobile Viewport Fix
import { isFirefox } from 'src/utils/user-agents'

interface Scroll {
  top: number
  left: number
  bottom?: number
  right?: number
}

export enum OrientationType {
  LANDSCAPE = 'landscape',
  PORTRAIT = 'portrait',
}

// REFERENCE: https://github.com/PaddleHQ/paddle-js/blob/f6af7c030c4aadf21a4522ca925e7f50b73dc97a/library/paddle.js#L3504

// (function (root, factory) {
//     if (typeof define === 'function' && define.amd) {
//       define('mobile-viewport-control', [], factory);
//     }
//     else if (typeof module === 'object' && module.exports) {
//       module.exports = factory();
//     }
//     else {
//       root.mobileViewportControl = factory();
//     }
//   }(this, function() {

//---------------------------------------------------------------------------
// State and Constants
//---------------------------------------------------------------------------

// A unique ID of the meta-viewport tag we must create
// to hook into and control the viewport.
let hookID = '__mobileViewportControl_hook__'

// A unique ID of the CSS style tag we must create to
// add rules for hiding the body.
let styleID = '__mobileViewPortControl_style__'

// An empirical guess for the maximum time that we have to
// wait before we are confident a viewport change has registered.
let refreshDelay = 200

// Original viewport state before freezing.
let originalScale: number
let originalScroll: Scroll

// Classes we use to make our css selector specific enough
// to hopefully override all other selectors.
// (mvc__ = mobileViewportControl prefix for uniqueness)
let hiddenClasses = [
  'mvc__a',
  'mvc__lot',
  'mvc__of',
  'mvc__classes',
  'mvc__to',
  'mvc__increase',
  'mvc__the',
  'mvc__odds',
  'mvc__of',
  'mvc__winning',
  'mvc__specificity',
]

//---------------------------------------------------------------------------
// Getting/Setting Scroll position
//---------------------------------------------------------------------------

function getScroll(): Scroll {
  return {
    top: window.pageYOffset || document.documentElement.scrollTop,
    left: window.pageXOffset || document.documentElement.scrollLeft,
  }
}

function setScroll(scroll: Scroll) {
  if (window.scrollTo) {
    window.scrollTo(scroll.left, scroll.top)
  } else {
    document.documentElement.scrollTop = scroll.top
    document.documentElement.scrollLeft = scroll.left
    document.body.scrollTop = scroll.top
    document.body.scrollLeft = scroll.left
  }
}

//---------------------------------------------------------------------------
// Getting Initial Viewport from <meta name='viewport'> tags
// but we also include implicit defaults.
//---------------------------------------------------------------------------

function getInitialViewport(withDefaults: boolean) {
  let viewport = {}

  if (withDefaults) {
    // These seem to be the defaults
    viewport = {
      'user-scalable': 'yes',
      'minimum-scale': '0',
      'maximum-scale': '10',
    }
  }

  let tags = document.querySelectorAll('meta[name=viewport]')
  let i, j, tag, content, keyvals, keyval
  for (i = 0; i < tags.length; i++) {
    tag = tags[i]
    content = tag.getAttribute('content')
    if (tag.id !== hookID && content) {
      keyvals = content.split(',')
      for (j = 0; j < keyvals.length; j++) {
        keyval = keyvals[j].split('=')
        if (keyval.length === 2) {
          viewport[keyval[0].trim()] = keyval[1].trim()
        }
      }
    }
  }
  return viewport
}

//---------------------------------------------------------------------------
// Calculating current viewport scale
// simplified from: http://menacingcloud.com/?c=viewportScale
//---------------------------------------------------------------------------

function getOrientation(): OrientationType {
  const width = window.innerWidth
  const height = window.innerHeight

  if (width > height) {
    return OrientationType.LANDSCAPE
  } else {
    return OrientationType.PORTRAIT
  }
}

function getOrientedScreenWidth(): number {
  const orientation = getOrientation()
  const sw = window.screen.width
  const sh = window.screen.height
  return orientation === OrientationType.PORTRAIT ? Math.min(sw, sh) : Math.max(sw, sh)
}

function getScale(): number {
  const visualViewportWidth = window.innerWidth
  const screenWidth = getOrientedScreenWidth()
  return screenWidth / visualViewportWidth
}

//---------------------------------------------------------------------------
// Get mobile OS
// from: http://stackoverflow.com/a/21742107
//---------------------------------------------------------------------------

function getMobileOS() {
  let userAgent = navigator.userAgent || navigator.vendor || window.opera
  if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i) || userAgent.match(/iPod/i)) {
    return 'iOS'
  } else if (userAgent.match(/Android/i)) {
    return 'Android'
  }
  return
}

//---------------------------------------------------------------------------
// Isolating an Element
//---------------------------------------------------------------------------

function isolatedStyle(elementID: string): string {
  let classes = hiddenClasses.join('.')
  return [
    // We effectively clear the <html> and <body> background
    // and sizing attributes.
    'html.' + classes + ',',
    'html.' + classes + ' > body {',
    '  background: #fff;',
    '  width: auto;',
    '  min-width: inherit;',
    '  max-width: inherit;',
    '  height: auto;',
    '  min-height: 100%;', // Was 'inherit', now '100%' (by @involer)
    '  max-height: inherit;',
    '  margin: 0;',
    '  padding: 0;',
    '  border: 0;',
    '  position: static;', // https://paddle.atlassian.net/browse/CO-3558
    '}',
    // hide everything in the body...
    'html.' + classes + ' > body > * {',
    '  display: none !important;',
    '}',
    // ...except the given element ID
    'html.' + classes + ' > body > #' + elementID + ' {',
    '  display: block !important;',
    '}',
  ].join('\n')
}

function isolate(elementID: string): void {
  // add classes to body tag to isolate all other elements
  let classes = hiddenClasses.join(' ')
  let html = document.documentElement
  html.className += ' ' + classes

  // add isolating style rules
  let style = document.createElement('style')
  style.id = styleID
  style.type = 'text/css'
  style.appendChild(document.createTextNode(isolatedStyle(elementID)))
  document.head.appendChild(style)
}

function undoIsolate(): void {
  // remove isolating classes from body tag
  let classes = hiddenClasses.join(' ')
  let html = document.documentElement
  html.className = html.className.replace(classes, '')

  // remove isolating style rules
  let style = document.getElementById(styleID) as Node
  document.head.removeChild(style)
  document.querySelectorAll('#' + styleID).forEach((el) => el.remove())
}

//---------------------------------------------------------------------------
// Freezing
//---------------------------------------------------------------------------

// Freeze the viewport to a given scale.
export function freeze(scale: number, ...args: string[]) {
  // optional arguments
  let isolateID, onDone

  // get optional arguments using their type
  if (typeof args[0] === 'number' || typeof args[0] === 'string') {
    isolateID = args[0]
    args.splice(0, 1)
  }
  if (typeof args[0] === 'function') {
    onDone = args[0]
  }

  // save original viewport state
  originalScroll = getScroll()
  originalScale = getScale()

  // isolate element if needed
  if (isolateID) {
    isolate(`${isolateID}`)
    setScroll({ top: 0, left: 0 })
  }

  // validate scale
  // (we cannot freeze scale at 1.0 on Android)
  if (scale === 1) {
    scale = 1.002
  }

  // add our new meta viewport tag
  let hook: HTMLMetaElement | null = document.getElementById(hookID) as HTMLMetaElement
  if (!hook) {
    hook = document.createElement('meta')
    hook.id = hookID
    hook.name = 'viewport'
    document.head.appendChild(hook)
  }

  // When freezing the viewport, we still enable
  // user-scalability and allow a tight zooming
  // margin.  Without this, UIWebView would simply
  // ignore attempts to set the scale.  But with this
  // solution, the next time the user pinch-zooms
  // in this state, the viewport will auto-snap
  // to our scale.

  let includeWidth = getMobileOS() === 'Android' && isFirefox()
  hook.setAttribute(
    'content',
    [
      'user-scalable=yes',
      'initial-scale=' + scale,
      'minimum-scale=' + scale,
      'maximum-scale=' + (scale + 0.004),
      includeWidth ? 'width=device-width' : null,
    ]
      .filter(Boolean)
      .join(','),
  )

  if (onDone) {
    setTimeout(onDone, refreshDelay)
  }
}

//---------------------------------------------------------------------------
// Thawing
//---------------------------------------------------------------------------

function thawWebkit(hook: HTMLElement, initial: object, onDone: Function) {
  // Restore the user's manual zoom.
  hook.setAttribute(
    'content',
    ['initial-scale=' + originalScale, 'minimum-scale=' + originalScale, 'maximum-scale=' + originalScale].join(','),
  )

  // Restore the page's zoom bounds.
  hook.setAttribute(
    'content',
    [
      'user-scalable=' + initial['user-scalable'],
      'minimum-scale=' + initial['minimum-scale'],
      'maximum-scale=' + initial['maximum-scale'],
      initial['width'] ? 'width=' + initial['width'] : null,
    ]
      .filter(Boolean)
      .join(','),
  )

  // Remove our meta viewport hook.
  document.head.removeChild(hook)

  setScroll(originalScroll)

  setTimeout(function () {
    if (onDone) onDone()
  }, refreshDelay)
}

function thawGecko(hook: HTMLElement, initial: object, onDone: Function) {
  // Restore the user's manual zoom.
  hook.setAttribute(
    'content',
    ['initial-scale=' + originalScale, 'minimum-scale=' + originalScale, 'maximum-scale=' + originalScale].join(','),
  )

  // Updating the scroll here is too early,
  // but it's used to force a refresh of the viewport
  // with our current desired scale.
  setScroll(originalScroll)

  setTimeout(function () {
    // Restore the page's zoom bounds.
    hook.setAttribute(
      'content',
      [
        'user-scalable=' + initial['user-scalable'],
        'minimum-scale=' + initial['minimum-scale'],
        'maximum-scale=' + initial['maximum-scale'],
        initial['width'] ? 'width=' + initial['width'] : null,
      ]
        .filter(Boolean)
        .join(','),
    )

    // Restore the scroll again now that the scale is correct.
    setScroll(originalScroll)

    // Remove our meta viewport hook.
    document.head.removeChild(hook)

    if (onDone) onDone()
  }, refreshDelay)
}

function thawBlink(hook: HTMLElement, initial: object, onDone: Function) {
  hook.setAttribute(
    'content',
    [
      'user-scalable=' + initial['user-scalable'],
      // WebView does not support this:
      //'initial-scale='+originalScale
      'initial-scale=' + initial['initial-scale'],
      'minimum-scale=' + initial['minimum-scale'],
      'maximum-scale=' + initial['maximum-scale'],
      initial['width'] ? 'width=' + initial['width'] : null,
    ]
      .filter(Boolean)
      .join(','),
  )

  setScroll(originalScroll)

  setTimeout(function () {
    document.head.removeChild(hook)
    if (onDone) onDone()
  }, refreshDelay)
}

// Thaw the viewport, restoring the scale and scroll to what it
// was before freezing.
export function thaw(onDone: Function) {
  // restore body visibility
  let style = document.getElementById(styleID)
  if (style) {
    undoIsolate()
  }

  // exit if there is nothing to thaw
  let hook = document.getElementById(hookID)
  if (!hook) {
    return
  }

  let initial = getInitialViewport(true)

  // thaw function defaults to webkit
  let thawFunc = thawWebkit
  let os = getMobileOS()
  if (os === 'Android') {
    thawFunc = isFirefox() ? thawGecko : thawBlink
  } else if (os === 'iOS') {
    thawFunc = thawWebkit
  }

  // call appropriate thaw function
  thawFunc(hook, initial, onDone)
}
