export function isApplePaySupported() {
  // Check if the current browser has ApplePay enabled
  const applePaySession = window.ApplePaySession
  let isSupported

  try {
    isSupported = applePaySession && applePaySession.canMakePayments()
  } catch (e) {
    isSupported = false
  }

  return !!isSupported
}
