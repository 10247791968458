export interface DataProps {
  completedSetup: boolean
  loadedAffiliateAnalytics: boolean
  failedLoadingAffiliateAnalytics: boolean
  loadedButtonStylesheet: boolean
  loadedAnimationStylesheet: boolean
  libraryVersion: string | null
}

class _Status {
  data: DataProps
  AudienceHasPopped: boolean = false
  AudienceLoadScrollDepth: number = 0
  AudienceCheckScrollDepth: number = 0
  constructor() {
    this.data = {
      completedSetup: false,
      loadedAffiliateAnalytics: false,
      failedLoadingAffiliateAnalytics: false,
      loadedButtonStylesheet: false,
      loadedAnimationStylesheet: false,
      libraryVersion: null,
    }
  }

  get completedSetup(): boolean {
    return this.data.completedSetup
  }

  set completedSetup(value: boolean) {
    this.data.completedSetup = value
  }

  get loadedAffiliateAnalytics(): boolean {
    return this.data.loadedAffiliateAnalytics
  }

  set loadedAffiliateAnalytics(value: boolean) {
    this.data.loadedAffiliateAnalytics = value
  }

  get failedLoadingAffiliateAnalytics(): boolean {
    return this.data.failedLoadingAffiliateAnalytics
  }

  set failedLoadingAffiliateAnalytics(value: boolean) {
    this.data.failedLoadingAffiliateAnalytics = value
  }

  get loadedButtonStylesheet(): boolean {
    return this.data.loadedButtonStylesheet
  }

  set loadedButtonStylesheet(value: boolean) {
    this.data.loadedButtonStylesheet = value
  }

  get loadedAnimationStylesheet(): boolean {
    return this.data.loadedAnimationStylesheet
  }

  set loadedAnimationStylesheet(value: boolean) {
    this.data.loadedAnimationStylesheet = value
  }

  get libraryVersion(): string | null {
    return this.data.libraryVersion
  }

  set libraryVersion(value: string | null) {
    this.data.libraryVersion = value
  }
}

export const Status = new _Status()
