export enum CHECKOUT_METHOD {
  OVERLAY = 'overlay',
  WINDOW = 'window',
  INLINE = 'inline',
  SDK = 'sdk',
  WIDE_OVERLAY = 'wide-overlay',
}

export enum DISPLAY_MODE {
  OVERLAY = 'overlay',
  INLINE = 'inline',
  SDK = 'sdk',
  POPUP = 'popup',
  WIDE_OVERLAY = 'wide-overlay',
}

export enum LOAD_METHOD {
  DIRECT = 'Direct',
}

export enum CHECKOUT_TYPE {
  FALLBACK = 'fallback',
  POPUP = 'popup',
  NORMAL = 'normal',
}

export enum DIRECTION {
  UP = 'up',
  DOWN = 'down',
  TOP = 'top',
  LEFT = 'left',
  CENTER = 'center',
}
