import { CLASSES, COLORS, IDS, POPUP_TYPE, getResource, POPUP_METHOD, ANALYTICS_EVENT } from 'src/constants'

import { Status, Environment, Options } from 'src/config'

import {
  logger,
  ready,
  hidePopup,
  showPopup,
  isFirefox,
  showLoading,
  hideLoading,
  post,
  analyticsEvent,
} from 'src/utils'
import { defaultConsentMessage, getConsentMessageWithVendor, loadConsentMessages } from 'src/utils/consent-message'

export interface PopupAttributesProps {
  isGdprEnabled?: boolean
  allowDismiss?: boolean
  dismissColor?: string
  vendorName?: string
  callback?: (data: SubscribeAudienceResponseProps) => void
  view?: {
    animations: {
      hide?: string
      show?: string
    }
    styles: {
      cta: {
        textColor?: string
        backgroundColor?: string
      }
      heading: {
        textColor?: string
      }
      subHeading: {
        textColor?: string
      }
      popup: {
        backgroundImage?: string | boolean
        backgroundSize?: string | boolean
        backgroundPosition?: string | boolean
        backgroundRepeat?: string | boolean
        backgroundColor?: string | boolean
      }
    }
  }
  strings?: {
    heading?: string
    subHeading?: string
    emailPlaceholder?: string
    cta?: string
    successMessage?: string | null | boolean
  }
  triggers?: {
    exitIntent?: boolean
    scrollDepth?: boolean | number
    timed?: boolean | number
  }
}

export enum CONSENT_COLLECTED_BY {
  VENDOR = 'vendor',
  PADDLE = 'paddle',
}

export const AUDIENCE_POPUP = 'paddlejs_popup'

const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const isSessionStorageSupported = (): boolean => {
  const testKey = 'test'
  try {
    const storage = window.sessionStorage
    storage.setItem(testKey, '1')
    storage.removeItem(testKey)

    return true
  } catch (error) {
    return false
  }
}

export const setSessionStore = (key: string, value: string) => {
  if (isSessionStorageSupported()) sessionStorage.setItem(key, value)
}

export const getSessionStore = (key: string) => {
  if (isSessionStorageSupported()) return sessionStorage.getItem(key)
  return null
}
export function logAudiencePopup(): void {
  // Set session storage, we won't show an automatic popup for the current browser session.
  setSessionStore(AUDIENCE_POPUP, '1')
}

export function allowAudiencePopup(): boolean {
  // Check session storage, if it's set, don't show the popup.
  const popup = getSessionStore(AUDIENCE_POPUP)

  return !(popup && popup === '1')
}

export function GenerateAudiencePopupHTML(popupId: string, attributes: PopupAttributesProps): string {
  const env = Environment.get()
  let popupHtml =
    `<div class="${CLASSES.PADDLE_POPUP} ${CLASSES.PADDLE_ANIMATED} paddle-` +
    attributes.view?.animations.show +
    '" aria-busy="true">'

  if (attributes.allowDismiss) {
    popupHtml += `<div class="${CLASSES.PADDLE_POPUP_CLOSE}">`
    popupHtml += `<a class="${CLASSES.PADDLE_POPUP_CLOSE_IMAGE}" href="#!"><img src="${getResource(env).CLOSE_IMAGE}-${
      attributes.dismissColor
    }.png" border="0" /></a>`
    popupHtml += '</div>'
  }

  let popupBackground = ''
  if (attributes.view?.styles.popup.backgroundImage) {
    popupBackground += "background-image: url('" + attributes.view.styles.popup.backgroundImage + "');"

    if (attributes.view.styles.popup.backgroundSize) {
      popupBackground += 'background-size: ' + attributes.view.styles.popup.backgroundSize + ';'
    }

    if (attributes.view.styles.popup.backgroundPosition) {
      popupBackground += 'background-position: ' + attributes.view.styles.popup.backgroundPosition + ';'
    }

    if (attributes.view.styles.popup.backgroundRepeat) {
      popupBackground += 'background-repeat: ' + attributes.view.styles.popup.backgroundRepeat + ';'
    }
  }

  popupHtml +=
    `<div class="${CLASSES.PADDLE_POPUP_INNER}" style="background-color: ` +
    attributes.view?.styles.popup.backgroundColor +
    '; ' +
    popupBackground +
    '">'

  if (attributes.strings?.heading) {
    popupHtml +=
      `<div class="${CLASSES.PADDLE_POPUP_HEADING}" style="color: ` +
      attributes.view?.styles.heading.textColor +
      ';">' +
      attributes.strings.heading +
      '</div>'
  }

  if (attributes.strings?.subHeading) {
    popupHtml +=
      `<div class="${CLASSES.PADDLE_POPUP_SUB_HEADING}" style="color: ` +
      attributes.view?.styles.subHeading?.textColor +
      ';">' +
      attributes.strings.subHeading +
      '</div>'
  }

  popupHtml += `<form class="${CLASSES.PADDLE_POPUP_FORM}">`
  popupHtml +=
    `<input type="email" required class="${CLASSES.PADDLE_POPUP_FIELD} ${CLASSES.PADDLE_POPUP_EMAIL}" placeholder="` +
    attributes.strings?.emailPlaceholder +
    '" />'

  if (attributes.isGdprEnabled) {
    popupHtml += `<label class="${CLASSES.PADDLE_POPUP_CHECKBOX}" for="newsletter-consent-input-` + popupId + '">' // style="display: none !important;"
    popupHtml +=
      `<input class="${CLASSES.PADDLE_POPUP_CHECKBOX_INPUT}" id="newsletter-consent-input-` +
      popupId +
      '" type="checkbox">'
    popupHtml +=
      `<span id="${IDS.PADDLE_POPUP_MARKETING_CONSENT_MESSAGE}" class="${CLASSES.PADDLE_POPUP_CHECKBOX_LABEL}" data-vendor-name="` +
      attributes.vendorName +
      '">' +
      defaultConsentMessage +
      '</span>'
    popupHtml += '</label>'
  }

  popupHtml +=
    `<input type="submit" class="${CLASSES.PADDLE_POPUP_CTA}" value="` +
    attributes.strings?.cta +
    '" style="color: ' +
    attributes.view?.styles.cta.textColor +
    '; background-color: ' +
    attributes.view?.styles.cta.backgroundColor +
    ';" />'
  popupHtml += '</form>'

  popupHtml += '</div>'
  popupHtml += '</div>'

  return popupHtml
}

export function audiencePopup(inputAttributes: PopupAttributesProps) {
  const vendorName = inputAttributes.vendorName || ''
  const isGdprEnabled = inputAttributes.vendorName ? true : false
  const popupId = '_' + Math.ceil(Math.random() * 10000000)
  const marketingConsentMessage = ''

  // Build 'attributes' object, wih default values, based on inputs passed to function.
  const attributes = {
    vendorName: vendorName,
    isGdprEnabled: isGdprEnabled,
    marketingConsentMessage: marketingConsentMessage,
    triggers: {
      exitIntent:
        typeof (inputAttributes.triggers || {}).exitIntent != 'undefined' ? inputAttributes.triggers?.exitIntent : true,
      scrollDepth:
        typeof (inputAttributes.triggers || {}).scrollDepth != 'undefined'
          ? inputAttributes.triggers?.scrollDepth
          : false, // false or value in px
      timed: typeof (inputAttributes.triggers || {}).timed != 'undefined' ? inputAttributes.triggers?.timed : false, // false or value in seconds
    },
    allowDismiss: typeof inputAttributes.allowDismiss != 'undefined' ? inputAttributes.allowDismiss : true,
    dismissColor: typeof inputAttributes.dismissColor != 'undefined' ? inputAttributes.dismissColor : 'dark',
    strings: {
      heading:
        typeof (inputAttributes.strings || {}).heading != 'undefined'
          ? inputAttributes.strings?.heading
          : 'Subscribe for updates!',
      subHeading:
        typeof (inputAttributes.strings || {}).subHeading != 'undefined'
          ? inputAttributes.strings?.subHeading
          : 'Subscribe to our email newsletter, and stay updated with our latest products, developments and offers.',
      emailPlaceholder:
        typeof (inputAttributes.strings || {}).emailPlaceholder != 'undefined'
          ? inputAttributes.strings?.emailPlaceholder
          : 'Email Address...',
      cta: typeof (inputAttributes.strings || {}).cta != 'undefined' ? inputAttributes.strings?.cta : 'Subscribe!',
      successMessage:
        typeof (inputAttributes.strings || {}).successMessage != 'undefined'
          ? inputAttributes.strings?.successMessage
          : 'Success! You are now subscribed!',
    },
    view: {
      animations: {
        show:
          typeof ((inputAttributes.view || {}).animations || {}).show != 'undefined'
            ? inputAttributes.view?.animations.show
            : 'bounceIn',
        hide:
          typeof ((inputAttributes.view || {}).animations || {}).hide != 'undefined'
            ? inputAttributes.view?.animations.hide
            : 'fadeOutUpBig',
      },
      styles: {
        heading: {
          textColor:
            typeof (((inputAttributes.view || {}).styles || {}).heading || {}).textColor != 'undefined'
              ? inputAttributes.view?.styles.heading.textColor
              : COLORS['000000'],
        },
        subHeading: {
          textColor:
            typeof (((inputAttributes.view || {}).styles || {}).subHeading || {}).textColor != 'undefined'
              ? inputAttributes.view?.styles.subHeading.textColor
              : COLORS['666666'],
        },
        popup: {
          backgroundColor:
            typeof (((inputAttributes.view || {}).styles || {}).popup || {}).backgroundColor != 'undefined'
              ? inputAttributes.view?.styles.popup.backgroundColor
              : COLORS['FFFFFF'],
          backgroundImage:
            typeof (((inputAttributes.view || {}).styles || {}).popup || {}).backgroundImage != 'undefined'
              ? inputAttributes.view?.styles.popup.backgroundImage
              : false,
          backgroundSize:
            typeof (((inputAttributes.view || {}).styles || {}).popup || {}).backgroundSize != 'undefined'
              ? inputAttributes.view?.styles.popup.backgroundSize
              : false,
          backgroundPosition:
            typeof (((inputAttributes.view || {}).styles || {}).popup || {}).backgroundPosition != 'undefined'
              ? inputAttributes.view?.styles.popup.backgroundPosition
              : false,
          backgroundRepeat:
            typeof (((inputAttributes.view || {}).styles || {}).popup || {}).backgroundRepeat != 'undefined'
              ? inputAttributes.view?.styles.popup.backgroundRepeat
              : false,
        },
        cta: {
          backgroundColor:
            typeof (((inputAttributes.view || {}).styles || {}).cta || {}).backgroundColor != 'undefined'
              ? inputAttributes.view?.styles.cta.backgroundColor
              : COLORS['4CAF50'],
          textColor:
            typeof (((inputAttributes.view || {}).styles || {}).cta || {}).textColor != 'undefined'
              ? inputAttributes.view?.styles.cta.textColor
              : COLORS['FFFFFF'],
        },
      },
    },
    callback: typeof inputAttributes.callback != 'undefined' ? inputAttributes.callback : () => {},
  }

  ready(_onPopupReady(popupId, attributes))

  return popupId
}

export const _onPopupReady = (popupId: string, attributes: PopupAttributesProps) => () => {
  const body = document.getElementsByTagName('body')[0]
  const audiencePopup: HTMLDivElement = document.createElement('div')
  audiencePopup.setAttribute(
    'class',
    `${CLASSES.PADDLE_RESET} ${CLASSES.PADDLE_POPUP_CONTAINER} ${CLASSES.PADDLE_POPUP_INSTANCE_ID}` +
      popupId +
      ` ${CLASSES.PADDLE_ANIMATED} ${CLASSES.PADDLE_FADE_IN} ${CLASSES.PADDLE_HIDDEN}`,
  )
  audiencePopup.innerHTML = GenerateAudiencePopupHTML(popupId, attributes)
  body.appendChild(audiencePopup)

  const popupDomElement: Element | null = document.getElementsByClassName(CLASSES.PADDLE_POPUP_INSTANCE_ID + popupId)[0]

  const marketingConsentCheckboxElement: HTMLInputElement = popupDomElement.getElementsByClassName(
    CLASSES.PADDLE_POPUP_CHECKBOX_INPUT,
  )[0] as HTMLInputElement

  const marketingConsentLabelElement: HTMLLabelElement = popupDomElement.getElementsByClassName(
    CLASSES.PADDLE_POPUP_CHECKBOX_LABEL,
  )[0] as HTMLLabelElement

  if (attributes.isGdprEnabled) {
    marketingConsentCheckboxElement.onchange = function () {
      if (marketingConsentCheckboxElement.checked) {
        marketingConsentLabelElement.style.color = '#666666'
      }
    }
  }

  loadConsentMessages()

  const close: HTMLElement = popupDomElement.getElementsByClassName(CLASSES.PADDLE_POPUP_CLOSE_IMAGE)[0] as HTMLElement
  close.onclick = function (e) {
    e.preventDefault()
    hidePopup(popupId, POPUP_TYPE.AUDIENCE)
  }

  const popupForm: HTMLElement | any = popupDomElement.getElementsByClassName(CLASSES.PADDLE_POPUP_FORM)[0]
  popupForm.onsubmit = function (event: Event) {
    event.preventDefault()
    if (!attributes.isGdprEnabled || marketingConsentCheckboxElement.checked) {
      _popupSubmit(popupId, attributes)
    } else {
      marketingConsentLabelElement.style.color = 'red'
    }
  }

  // Setup Popup Triggers
  if (attributes.triggers?.exitIntent) {
    // Firefox mouseleave behaviour is erratic, and it doesn't always trigger when the mouse is outside
    // the body, so we need some leeway.
    let mouseLeaveOffset = 0
    let elementToListen: Document | HTMLBodyElement = document

    if (isFirefox()) {
      mouseLeaveOffset = 30
      elementToListen = body
    }

    const checkPopupTrigger = function (e: MouseEvent) {
      if (e.clientY <= mouseLeaveOffset) {
        showPopup(popupId, POPUP_METHOD.EXIT_INTENT, POPUP_TYPE.AUDIENCE)
      }
    }

    logger.log('Exit-intent audience popup enabled, will pop upon users must entering browser address bar/tabs.')

    // Turns out that Firefox doesn't respond to mouseleave on document, so we need to attach the event
    // on body. Chrome, Safari and others behave as expected.
    elementToListen.addEventListener('mouseleave', checkPopupTrigger as EventListenerOrEventListenerObject)
  }

  if (attributes.triggers?.scrollDepth !== false) {
    if (attributes.triggers?.scrollDepth === true) {
      logger.log('Scroll-depth audience popup enabled, will pop with any scroll activity.')
    } else {
      logger.log(
        'Scroll-depth audience popup enabled, will pop after scrolling ' + attributes.triggers?.scrollDepth + 'px.',
      )
    }

    Status.AudienceHasPopped = false
    Status.AudienceLoadScrollDepth = window.scrollY

    if (Status.AudienceLoadScrollDepth <= 100) {
      window.onscroll = function (_) {
        clearTimeout(Status.AudienceCheckScrollDepth)
        window.checkScrollDepth = setTimeout(function () {
          const scrollDepth = window.scrollY
          // @ts-ignore
          if (attributes.triggers?.scrollDepth && scrollDepth >= attributes.triggers?.scrollDepth) {
            if (!Status.AudienceHasPopped) {
              Status.AudienceHasPopped = true
              showPopup(popupId, POPUP_METHOD.SCROLL_DEPTH, POPUP_TYPE.AUDIENCE)
            }
          }
        }, 300)
      }
    }
  }

  if (attributes.triggers?.timed) {
    logger.log('Timed audience popup enabled, popping in ' + attributes.triggers.timed + ' seconds.')
    setTimeout(function () {
      showPopup(popupId, POPUP_METHOD.TIMED, POPUP_TYPE.AUDIENCE)
    }, (attributes.triggers.timed as number) * 1000)
  }
}

function emailFromPopup(popupId: string): string | false {
  const email: HTMLInputElement | null = document
    .getElementsByClassName(CLASSES.PADDLE_POPUP_INSTANCE_ID + popupId)[0]
    .getElementsByClassName(CLASSES.PADDLE_POPUP_EMAIL)[0] as HTMLInputElement
  if (email) {
    return email.value
  } else {
    return false
  }
}

export function _popupSubmit(popupId: string, popupAttributes: PopupAttributesProps) {
  logger.log('Audience popup submitted.')

  const email = emailFromPopup(popupId)
  const callback = popupAttributes.callback || function () {}
  let successMessage: string | undefined | null | boolean = ''

  try {
    successMessage = popupAttributes.strings?.successMessage
    if (successMessage === undefined || successMessage === null) throw new Error('error')
  } catch (error) {
    logger.log('The success message attribute is not valid')
  }

  const newAudienceMemberAttributes = {
    marketing_consent: 0,
    email: email,
    consent_collected_by: CONSENT_COLLECTED_BY.VENDOR,
  } as any

  if (popupAttributes.isGdprEnabled && popupAttributes.vendorName) {
    newAudienceMemberAttributes.marketing_consent = 1
    newAudienceMemberAttributes.consent_collected_by = CONSENT_COLLECTED_BY.PADDLE
    newAudienceMemberAttributes.marketing_consent_message_base = getConsentMessageWithVendor(popupAttributes.vendorName)
    newAudienceMemberAttributes.marketing_consent_message_localised = getConsentMessageWithVendor(
      popupAttributes.vendorName,
    )
  }

  if (typeof email === 'string' && email.match(EMAIL_REGEX)) {
    showLoading()
    addUserToAudience(newAudienceMemberAttributes, function (data: SubscribeAudienceResponseProps) {
      hideLoading()
      if (data.success) {
        if (typeof callback == 'function') {
          callback(data)
        }

        if (successMessage) {
          alert(successMessage)
        }

        hidePopup(popupId, POPUP_TYPE.AUDIENCE)
      } else {
        if (typeof callback == 'function') {
          callback(data)
        }

        alert('Error: ' + data.error)
      }
    })
  } else {
    alert('Please enter a valid email address.')
  }
}

export interface UserAttributeProps {
  email?: string
  source?: string
  medium?: string
}

export interface SubscribeAudienceCallbackProps {
  (data: SubscribeAudienceResponseProps): void
}

export function addUserToAudience(
  userAttributes: UserAttributeProps = {},
  callback: SubscribeAudienceCallbackProps = () => {},
): false | void {
  logger.log('Audience subscription API triggered.')

  const audienceApiUrl: string = Environment.defaults().audienceApi.replace('{vendor_id}', `${Options.vendor}`)

  if (!userAttributes.email) {
    return false
  }

  userAttributes.source = 'Import'
  userAttributes.medium = 'Paddle.js'

  post(
    audienceApiUrl,
    new URLSearchParams(userAttributes as any),
    _handleSubscribeAudienceResponse(userAttributes, callback),
  )
}

export interface SubscribeAudienceResponseProps {
  success: boolean
  email?: string
  user_id?: string
  error?: string
}

interface ResponseProps {
  error: {
    message: string
  }
  success?: boolean
  user_id: string
}

export const _handleSubscribeAudienceResponse =
  (userAttributes: UserAttributeProps, callback: SubscribeAudienceCallbackProps | string) => (data: string) => {
    const dataObj = JSON.parse(data) as ResponseProps

    if (typeof dataObj.success != 'undefined' && dataObj.success === false) {
      let callbackArguments = {
        success: false,
        error: dataObj.error.message,
      }

      if (typeof callback == 'function') {
        callback(callbackArguments)
      } else if (typeof window[callback] == 'function') {
        const thisCallback = window[callback] as any
        thisCallback(callbackArguments)
      } else {
        alert(dataObj.error.message)
      }
    } else {
      if (dataObj.user_id) {
        analyticsEvent(ANALYTICS_EVENT.AUDIENCE_SUBSCRIBE)

        let callbackArguments = {
          success: true,
          email: userAttributes.email,
          user_id: dataObj.user_id,
        }

        if (typeof callback == 'function') {
          callback(callbackArguments)
        } else if (typeof window[callback] == 'function') {
          const thisCallback = window[callback] as any
          thisCallback(callbackArguments)
        } else {
          alert("You've been subscribed successfully!")
        }
      }
    }
  }

export function audienceSubscribe(
  email: string,
  marketingConsent: boolean | SubscribeAudienceCallbackProps,
  callback: SubscribeAudienceCallbackProps,
) {
  if (typeof callback == 'undefined' && typeof marketingConsent === 'function') {
    callback = marketingConsent
    marketingConsent = true
  }

  const newAudienceMemberAttributes = {
    marketing_consent: marketingConsent ? 1 : 0,
    email: email,
  }

  addUserToAudience(newAudienceMemberAttributes, callback)
}
