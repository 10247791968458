export const DOMAIN_CATEGORIES = {
  'paddle.com': {
    name: 'Paddle',
    type: 'Marketplace',
  },
  creatable: {
    name: 'Creatable',
    type: 'Marketplace',
  },
  'facebook.com': {
    name: 'Facebook',
    type: 'Social',
  },
  'fb.com': {
    name: 'Facebook',
    type: 'Social',
  },
  't.co': {
    name: 'Twitter',
    type: 'Social',
  },
  'twitter.com': {
    name: 'Twitter',
    type: 'Social',
  },
  'reddit.com': {
    name: 'Reddit',
    type: 'Social',
  },
  'medium.com': {
    name: 'Medium',
    type: 'Social',
  },
  'news.ycombinator.com': {
    name: 'Hacker News',
    type: 'Social',
  },
  'designernews.com': {
    name: 'Designer News',
    type: 'Social',
  },
  'producthunt.com': {
    name: 'Product Hunt',
    type: 'Social',
  },
  'paypal.com': {
    name: 'PayPal',
    type: 'Marketplace',
  },
  'my.paddle.com': {
    name: 'Paddle',
    type: 'Marketplace',
  },
  'cultofmac.com': {
    name: 'Cult of Mac',
    type: 'Article',
  },
  'mail.yahoo': {
    name: 'Yahoo Mail',
    type: 'Email',
  },
  'mail.google': {
    name: 'Gmail',
    type: 'Email',
  },
  gmail: {
    name: 'Gmail',
    type: 'Email',
  },
  'mail.google.com': {
    name: 'Gmail',
    type: 'Email',
  },
  'mail.live': {
    name: 'Live Mail',
    type: 'Email',
  },
  'mail.aol.com': {
    name: 'Aol Mail',
    type: 'Email',
  },
  'mail.qq.com': {
    name: 'QQ Mail',
    type: 'Email',
  },
  'mail.comcast.net': {
    name: 'Comcast Mail',
    type: 'Email',
  },
  'earthlink.net': {
    name: 'Earthlink Mail',
    type: 'Email',
  },
  bing: {
    name: 'Bing',
    type: 'Search',
  },
  yahoo: {
    name: 'Yahoo',
    type: 'Search',
  },
  google: {
    name: 'Google',
    type: 'Search',
  },
  localhost: {
    name: 'Localhost',
    type: 'Local',
  },
}
