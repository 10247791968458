import { DOMAIN_CATEGORIES, URL_PARAMS } from 'src/constants'

import { urlParam, analyticsDefaults, getSessionStore, setSessionStore } from 'src/utils'
import { DomainMeta, CampaignMeta } from 'src/utils/campaign.type'

export const REFERRER = 'paddlejs_campaign_referrer'
export function campaignDomainCategory(domain?: string): DomainMeta | false {
  let categorizedDomain: DomainMeta | boolean = false

  if (domain !== undefined && domain !== '' && typeof DOMAIN_CATEGORIES[domain] != 'undefined') {
    categorizedDomain = {
      type: DOMAIN_CATEGORIES[domain].type,
      name: DOMAIN_CATEGORIES[domain].name,
    }
  }

  return categorizedDomain
}

// Grabs URL campaign attributes, stores as cookies and defaults to sensible values, returns object representing the entire campaign.
export function campaignAttributes(): CampaignMeta {
  let returnCampaign: CampaignMeta = {} as any
  if (typeof urlParam(URL_PARAMS.P_TOK) != 'undefined' && urlParam(URL_PARAMS.P_TOK) !== '') {
    returnCampaign.Affiliate = true
    returnCampaign.AffiliateData = {
      token: urlParam(URL_PARAMS.P_TOK),
      link: urlParam(URL_PARAMS.P_LINK),
      affiliate: urlParam(URL_PARAMS.P_AID),
      seller: urlParam(URL_PARAMS.P_SID),
    }
  } else {
    returnCampaign.Affiliate = false
    returnCampaign.AffiliateData = {
      token: false,
      link: false,
      affiliate: false,
      seller: false,
    }
  }
  // paddle_ref
  if (typeof urlParam(URL_PARAMS.PADDLE_REF) != 'undefined' && urlParam(URL_PARAMS.PADDLE_REF) !== '') {
    returnCampaign.PaddleRef = urlParam(URL_PARAMS.PADDLE_REF)
  } else {
    returnCampaign.PaddleRef = false
  }

  // referring domain
  if (
    typeof getSessionStore(REFERRER) != 'undefined' &&
    getSessionStore(REFERRER) !== '' &&
    getSessionStore(REFERRER) !== null
  ) {
    returnCampaign.Referrer = getSessionStore(REFERRER) || ''
  } else {
    if (typeof document.referrer.split('/')[2] != 'undefined' && document.referrer.split('/')[2] !== '') {
      returnCampaign.Referrer = document.referrer.split('/')[2]
      setSessionStore(REFERRER, returnCampaign.Referrer)
    } else {
      returnCampaign.Referrer = false
    }
  }
  // referring domain categorisation
  if (returnCampaign.Referrer) {
    let referrerMeta = campaignDomainCategory(returnCampaign.Referrer)
    if (referrerMeta) {
      returnCampaign.Referrer = referrerMeta.name
      returnCampaign.ReferrerCategory = referrerMeta.type
    } else {
      returnCampaign.ReferrerCategory = false
    }
  } else {
    returnCampaign.ReferrerCategory = false
  }
  return returnCampaign
}

export function isAffiliate(): boolean {
  if (affiliateToken()) {
    let analyticsData = analyticsDefaults()
    return analyticsData._Affiliate?.IsAffiliate
  } else {
    return false
  }
}

export function affiliateToken(): string | boolean {
  let campaignData = campaignAttributes()
  return campaignData?.AffiliateData?.token || false
}
