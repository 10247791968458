import { Environment, Options } from 'src/config'

import { jsonp, HistoryDataProps } from 'src/utils'

export interface UserHistoryCallbackProps {
  (data: HistoryDataProps): void
}

export function userHistory(email: string, product_id: string | null = null, callback?: UserHistoryCallbackProps) {
  if (email !== '') {
    let productFilter = ''
    if (product_id) {
      productFilter = '&product_id=' + product_id
    }

    jsonp(
      Environment.defaults().userHistoryApi +
        '?email=' +
        encodeURIComponent(email) +
        '&vendor_id=' +
        Options.vendor +
        productFilter,
      _handleUserHistoryResponse(callback),
    )
  } else {
    if (typeof callback == 'function') {
      callback({
        success: false,
        error: {
          code: 107,
          message: 'A valid email address is required, please try again',
        },
      })
    } else {
      alert('You must enter a valid email address.')
    }
  }
}

export const _handleUserHistoryResponse = (callback?: UserHistoryCallbackProps) => (data: HistoryDataProps) => {
  if (typeof callback == 'function') {
    callback(data)
  } else {
    alert(data.message)
  }
}
