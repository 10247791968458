export enum URL_PARAMS {
  PADDLE_OPEN = 'paddle_open',
  PADDLE_ENVIRONMENT = 'paddle_env',
  PADDLE_REF = 'paddle_ref',
  P_TOK = 'p_tok',
  P_LINK = 'p_link',
  P_AID = 'p_aid',
  P_SID = 'p_sid',
  PADDLE_COUPON = 'paddle_coupon',
}
