export const assigned = (value: any): boolean => value !== undefined && value !== null && value !== ''

export const checkIsNonEmptyString = (value: any): boolean => typeof value === 'string' && value !== ''

export const isFalsy = (value: any): boolean => value !== '' && (value === 'false' || value === null || value === '0')

export const isTruthy = (value: any): boolean => value === true || value === 'true' || value === '1' || value === 1

export const hasValue = (value: any) => typeof value != 'undefined' && value !== false && value !== ''

export function isObjectValid(object?: Record<string, string | number | boolean> | string) {
  return (
    typeof object === 'object' &&
    object && // To prevent null values
    !Array.isArray(object) && // Arrays will also be of type "object" and Object.keys will return valid length for an array
    Object.keys(object).length > 0
  )
}
