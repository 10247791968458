function scrubbingPreview() {

    Modernizr.on('webp', function () {
        Modernizr.on('jpeg2000', function () {
            Modernizr.on('jpegxr', function () {
                window.setTimeout(function () {
                    $('#screenshot-container').waitForImages(function () {
                        $("#screenshot").removeClass("collapsed");
                        $("#screenshot-container").removeClass("hidden");
                        $("#screenshot").addClass("shadow");

                        window.setTimeout(function () {
                            $("#scrubbing-preview-container").removeClass("hidden");
                        }, 500)
                        window.setTimeout(function () {
                            $("#scrubbing-preview-container").removeClass("animated-opacity");
                        }, 1500)
                    })
                }, 100);
            })
        })
    })

    updateScrubbingPreview = function () {
        var s = document.documentElement.scrollTop || document.body.scrollTop;
        var max = 200;
        var extra = 50;
        var images = 12;
        s = Math.max(0, s);
        s = Math.min(s, max + extra);

        if (window.oldS != s) {
            var image = Math.min(Math.round((s / max) * images), images);
            var e = $("#scrubbing-preview-container")[0]
            var e2 = $("#scrubbing-preview")[0]
            var pos = (- image * 426) + "px";
            if (e2.style.backgroundPositionX != pos) {
                e2.style.backgroundPositionX = pos;
            }
            e.style.transform = "translateX(" + Math.min(s, max) * 0.5 + "px)";

            if (!$("#scrubbing-preview-container").hasClass("animated-opacity")) {
                var opacity = 1 - Math.max((s - max), 0) / extra;
                e.style.opacity = opacity;
            }
        }
        window.requestAnimationFrame(updateScrubbingPreview);
    }
    window.requestAnimationFrame(updateScrubbingPreview);
}

if ($("#scrubbing-preview")[0] !== undefined) {
    scrubbingPreview();
}

