import { Status, Options } from 'src/config'

import { CheckoutInputAttributesProps } from 'src/classes/checkout'

import { isApplePaySupported, isMobile, campaignAttributes, checkIsNonEmptyString } from 'src/utils'
import { setSessionStore } from 'src/utils/audience'
import { CampaignMeta } from 'src/utils/campaign.type'

export const PADDLE_REF = 'paddlejs_campaign_paddle_ref'

export function buildPaddleReferrerString(referrer?: string | null) {
  var analyticsData = analyticsDefaults() as any
  if (referrer) return analyticsData._Campaign.CampaignSummaryString + ' / ' + referrer.replace('/', '')
  return analyticsData._Campaign.CampaignSummaryString
}

export function analyticsContext() {
  let unknown = 'Unknown'

  // screen
  let screenSize = ''
  if (window.screen.width) {
    let width = window.screen.width ? window.screen.width : ''
    let height = window.screen.height ? window.screen.height : ''
    screenSize += '' + width + ' x ' + height
  }

  //browser
  let nVer = navigator.appVersion
  let nAgt = navigator.userAgent
  let browser = navigator.appName
  let version = '' + parseFloat(navigator.appVersion)
  let majorVersion = parseInt(navigator.appVersion, 10)
  let verOffset, ix

  // Opera
  if ((verOffset = nAgt.indexOf('Opera')) !== -1) {
    browser = 'Opera'
    version = nAgt.substring(verOffset + 6)
    if ((verOffset = nAgt.indexOf('Version')) !== -1) {
      version = nAgt.substring(verOffset + 8)
    }
  }
  // MSIE
  else if ((verOffset = nAgt.indexOf('MSIE')) !== -1) {
    browser = 'Microsoft Internet Explorer'
    version = nAgt.substring(verOffset + 5)
  }

  //IE 11 no longer identifies itself as MS IE, so trap it
  //http://stackoverflow.com/questions/17907445/how-to-detect-ie11
  else if (browser === 'Netscape' && nAgt.indexOf('Trident/') !== -1) {
    browser = 'Microsoft Internet Explorer'
    version = nAgt.substring(verOffset + 5)
    if ((verOffset = nAgt.indexOf('rv:')) !== -1) {
      version = nAgt.substring(verOffset + 3)
    }
  }

  // Chrome
  else if ((verOffset = nAgt.indexOf('Chrome')) !== -1) {
    browser = 'Chrome'
    version = nAgt.substring(verOffset + 7)
  }
  // Safari
  else if ((verOffset = nAgt.indexOf('Safari')) !== -1) {
    browser = 'Safari'
    version = nAgt.substring(verOffset + 7)
    if ((verOffset = nAgt.indexOf('Version')) !== -1) {
      version = nAgt.substring(verOffset + 8)
    }

    // Chrome on iPad identifies itself as Safari. Actual results do not match what Google claims
    //  at: https://developers.google.com/chrome/mobile/docs/user-agent?hl=ja
    //  No mention of chrome in the user agent string. However it does mention CriOS, which presumably
    //  can be keyed on to detect it.
    if (nAgt.indexOf('CriOS') !== -1) {
      //Chrome on iPad spoofing Safari...correct it.
      browser = 'Chrome'
      //Don't believe there is a way to grab the accurate version number, so leaving that for now.
    }
  }
  // Firefox
  else if ((verOffset = nAgt.indexOf('Firefox')) !== -1) {
    browser = 'Firefox'
    version = nAgt.substring(verOffset + 8)
  }
  // Other browsers
  else if (nAgt.lastIndexOf(' ') + 1 < nAgt.lastIndexOf('/')) {
    browser = unknown
    version = '0'
  }
  // trim the version string
  if ((ix = version.indexOf(';')) !== -1) version = version.substring(0, ix)
  if ((ix = version.indexOf(' ')) !== -1) version = version.substring(0, ix)
  if ((ix = version.indexOf(')')) !== -1) version = version.substring(0, ix)

  majorVersion = parseInt('' + version, 10)
  if (isNaN(majorVersion)) {
    version = '' + parseFloat(navigator.appVersion)
    majorVersion = parseInt(navigator.appVersion, 10)
  }

  // mobile version
  let mobile = /Mobile|mini|Fennec|Android|iP(ad|od|hone)/.test(nVer)

  // cookie
  let cookieEnabled = navigator.cookieEnabled

  if (typeof navigator.cookieEnabled == 'undefined' && !cookieEnabled) {
    document.cookie = 'testcookie=1; SameSite=Lax;'
    cookieEnabled = document.cookie.indexOf('testcookie=') !== -1
    document.cookie = 'testcookie=1; expires=Thu, 01-Jan-1970 00:00:01 GMT; SameSite=Lax;'
  }

  // system
  let os = unknown
  let clientStrings = [
    { s: 'Windows 3.11', r: /Win16/ },
    { s: 'Windows 95', r: /(Windows 95|Win95|Windows_95)/ },
    { s: 'Windows ME', r: /(Win 9x 4.90|Windows ME)/ },
    { s: 'Windows 98', r: /(Windows 98|Win98)/ },
    { s: 'Windows CE', r: /Windows CE/ },
    { s: 'Windows 2000', r: /(Windows NT 5.0|Windows 2000)/ },
    { s: 'Windows XP', r: /(Windows NT 5.1|Windows XP)/ },
    { s: 'Windows Server 2003', r: /Windows NT 5.2/ },
    { s: 'Windows Vista', r: /Windows NT 6.0/ },
    { s: 'Windows 7', r: /(Windows 7|Windows NT 6.1)/ },
    { s: 'Windows 8.1', r: /(Windows 8.1|Windows NT 6.3)/ },
    { s: 'Windows 8', r: /(Windows 8|Windows NT 6.2)/ },
    { s: 'Windows NT 4.0', r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/ },
    { s: 'Windows ME', r: /Windows ME/ },
    { s: 'Android', r: /Android/ },
    { s: 'Open BSD', r: /OpenBSD/ },
    { s: 'Sun OS', r: /SunOS/ },
    { s: 'Linux', r: /(Linux|X11)/ },
    { s: 'iOS', r: /(iPhone|iPad|iPod)/ },
    { s: 'Mac OS X', r: /Mac OS X/ },
    { s: 'Mac OS', r: /(MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
    { s: 'QNX', r: /QNX/ },
    { s: 'UNIX', r: /UNIX/ },
    { s: 'BeOS', r: /BeOS/ },
    { s: 'OS/2', r: /OS\/2/ },
    { s: 'Search Bot', r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/ },
  ]
  for (let id in clientStrings) {
    let cs = clientStrings[id]
    if (cs.r.test(nAgt)) {
      os = cs.s
      break
    }
  }

  if (/Windows/.test(os)) {
    os = 'Windows'
  }

  let browserDetail = {
    screen: screenSize,
    browser: browser,
    browserVersion: version,
    mobile: mobile,
    os: os,
    cookies: cookieEnabled,
  }

  return browserDetail
}

export interface AnalyticsDefaultType {
  _Library?: Library
  _Request?: Request
  _Affiliate: Affiliate
  _Campaign: Campaign
  _SDK?: SDK
  _Vendor: number | null
  xc?: Record<string, any>
}

export interface AnalyticsType {
  library?: Library
  request?: Request
  affiliate?: Affiliate
  campaign?: Campaign
  SDK?: SDK
  vendor?: number | null
  xc?: Record<string, any>
}

export interface Coupon {
  coupon_code?: string
}

export interface Customer {
  currency: string
  unit: string
  unit_tax: string
  total: string
  total_tax: string
}

export interface Vendor {
  currency: string
  unit: string
  unit_tax: string
  total: string
  total_tax: string
}

export interface Prices {
  customer: Customer
  vendor: Vendor
}

export interface Checkout {
  created_at: string
  completed: boolean
  id: string
  coupon: Coupon
  passthrough?: any
  prices: Prices
  redirect_url?: any
  test_variant: string
}

export interface Product {
  quantity: number
  id: number
  name: string
}

export interface User {
  id: string
  email: string
  country: string
}

export interface EventData {
  checkout: Checkout
  product: Product
  user: User
  available_payment_methods: string
  available_payment_methods_count: number
}

export interface Library {
  Version?: string | null
  LoadMethod?: string
}

export interface Request {
  Secure?: boolean
  Domain?: string
  Page?: string
  Mobile?: boolean
  Browser?: string
  Platform?: string
  ApplePaySupported?: boolean
}

export interface Affiliate {
  IsAffiliate: boolean
  AffiliateToken: string | null
}

export interface Referrer {
  Name: string | null
  Type: string | null
}

export interface Campaign {
  Referrer: Referrer
  Paddle: string | null
  Name?: string | null
  Source?: string | null
  Medium?: string | null
  Term?: string | null
  CampaignSummaryString?: string
}

export interface SDK {}

export interface CampaignData {
  _Library: Library
  _Request: Request
  _Affiliate: Affiliate
  _Campaign: Campaign
  _SDK: SDK
  _Vendor: number
}

export interface EventProps {
  event: string
  eventData: EventData
  checkoutData: CheckoutInputAttributesProps
  campaignData: CampaignData
}

export function analyticsDefaults() {
  let analyticsDefaultValues: AnalyticsDefaultType = {} as any
  analyticsDefaultValues._Library = {}
  analyticsDefaultValues._Request = {}
  analyticsDefaultValues._Affiliate = {} as Affiliate
  analyticsDefaultValues._Campaign = {} as Campaign
  analyticsDefaultValues._Campaign.Referrer = {} as Referrer
  analyticsDefaultValues._SDK = {}

  // Library Defaults
  analyticsDefaultValues._Library.Version = Status.libraryVersion
  analyticsDefaultValues._Library.LoadMethod = Options.loadMethod

  // Vendor Defaults
  analyticsDefaultValues._Vendor = Options.vendor || null

  // Request Defaults
  analyticsDefaultValues._Request.Secure = window.location.protocol === 'https:'
  analyticsDefaultValues._Request.Domain =
    Options.sdk && Options.sdkAttributes && Options.sdkAttributes.bundleIdentifier
      ? Options.sdkAttributes.bundleIdentifier
      : window.location.host.replace(/www\./, '')
  analyticsDefaultValues._Request.Page = window.location.origin + window.location.pathname
  analyticsDefaultValues._Request.Mobile = isMobile()
  analyticsDefaultValues._Request.Browser = Options.sdk ? 'SDK' : analyticsContext().browser || 'Unknown'
  analyticsDefaultValues._Request.Platform = Options.sdk ? 'SDK' : isMobile() ? 'Mobile' : 'Web'
  analyticsDefaultValues._Request.ApplePaySupported = isApplePaySupported()

  // SDK Values
  if (Options.sdk) {
    analyticsDefaultValues._SDK = Options.sdkAttributes || {}
  }

  // Campaign/Referrer Defaults
  let campaignData: CampaignMeta = campaignAttributes()

  analyticsDefaultValues._Campaign.Referrer.Name = campaignData.Referrer ? (campaignData.Referrer as string) : null
  analyticsDefaultValues._Campaign.Referrer.Type = campaignData.ReferrerCategory
    ? (campaignData.ReferrerCategory as string)
    : null
  analyticsDefaultValues._Campaign.Paddle = campaignData.PaddleRef ? (campaignData.PaddleRef as string) : null
  analyticsDefaultValues._Campaign.Name = campaignData.Campaign ? campaignData.Campaign : null
  analyticsDefaultValues._Campaign.Source = Options.sdk ? 'SDK' : campaignData.Source ? campaignData.Source : null
  analyticsDefaultValues._Campaign.Medium = campaignData.Medium ? campaignData.Medium : null
  analyticsDefaultValues._Campaign.Term = campaignData.Term ? campaignData.Term : null

  // Affiliate Info
  analyticsDefaultValues._Affiliate.IsAffiliate = !!campaignData.Affiliate
  analyticsDefaultValues._Affiliate.AffiliateToken = campaignData.AffiliateData?.token
    ? campaignData.AffiliateData.token
    : null

  // Build a sanitised CampaignSummaryString based on the above values.
  // Format: <checkoutDomain> / <affiliate> / <referrer> / <paddleCampaign> / <campaign>
  // @todo Make this pass a campaign/referrer object to the checkout instead of a string once we've added parsing for it Paddle-side.
  analyticsDefaultValues._Campaign.CampaignSummaryString = ''

  if (analyticsDefaultValues._Request.Domain != null) {
    if (checkIsNonEmptyString(analyticsDefaultValues._Campaign.CampaignSummaryString))
      analyticsDefaultValues._Campaign.CampaignSummaryString += ' / '
    analyticsDefaultValues._Campaign.CampaignSummaryString += analyticsDefaultValues._Request.Domain.replace('/', '')
  }

  if (analyticsDefaultValues._Affiliate.IsAffiliate) {
    if (checkIsNonEmptyString(analyticsDefaultValues._Campaign.CampaignSummaryString))
      analyticsDefaultValues._Campaign.CampaignSummaryString += ' / '
    analyticsDefaultValues._Campaign.CampaignSummaryString += 'Affiliate'
  }

  if (analyticsDefaultValues._Campaign.Referrer.Name != null) {
    if (checkIsNonEmptyString(analyticsDefaultValues._Campaign.CampaignSummaryString))
      analyticsDefaultValues._Campaign.CampaignSummaryString += ' / '
    analyticsDefaultValues._Campaign.CampaignSummaryString += analyticsDefaultValues._Campaign.Referrer.Name.replace(
      '/',
      '',
    )
  }

  if (analyticsDefaultValues._Campaign.Paddle != null) {
    if (checkIsNonEmptyString(analyticsDefaultValues._Campaign.CampaignSummaryString))
      analyticsDefaultValues._Campaign.CampaignSummaryString += ' / '
    analyticsDefaultValues._Campaign.CampaignSummaryString += analyticsDefaultValues._Campaign.Paddle.replace('/', '')
  }

  if (analyticsDefaultValues._Campaign.Name != null) {
    if (checkIsNonEmptyString(analyticsDefaultValues._Campaign.CampaignSummaryString))
      analyticsDefaultValues._Campaign.CampaignSummaryString += ' / '
    analyticsDefaultValues._Campaign.CampaignSummaryString += analyticsDefaultValues._Campaign.Name.replace('/', '')
  }

  // Override the 'Campaign Summary String' for SDK checkouts.
  if (Options.sdk) {
    analyticsDefaultValues._Campaign.CampaignSummaryString = Options.sdkAttributes?.appName + ' In-app Purchase (SDK)'
  }

  return analyticsDefaultValues
}

export function setPaddleCampaign(campaignValue: string = '') {
  if (campaignValue != null && campaignValue !== '') {
    setSessionStore(PADDLE_REF, `${campaignValue}`)
  }
}
