// More info at https://developer.paddle.com/reference/paddle-js/checkout-events
export enum CHECKOUT_EVENTS {
  CHECKOUT_PING_SIZE = 'Checkout.Ping.Size',
  CHECKOUT_CLOSE = 'Checkout.Close',
  CHECKOUT_COMPLETE = 'Checkout.Complete',
  CHECKOUT_LOADED = 'Checkout.Loaded',
  CHECKOUT_FAILED = 'Checkout.Failed',
  CHECKOUT_REMOVE_SPINNER = 'Checkout.RemoveSpinner',
  UPSELL_DIALOG_POSITION = 'Checkout.UpsellDialogPosition',
}

export enum ACTIONS {
  CLOSE = 'close',
  FAILED = 'failed',
  EVENT = 'event',
  COMPLETE = 'complete',
}
