import { CHECKOUT_TYPE, DISPLAY_MODE, URL_PARAMS } from 'src/constants'
import {
  DEV_CREATE_CHECKOUT_DOMAIN,
  PRODUCTION_CREATE_CHECKOUT_DOMAIN,
  SANDBOX_CREATE_CHECKOUT_DOMAIN,
  STAGING_CREATE_CHECKOUT_DOMAIN,
} from 'src/constants/resources'

import { Environment, Options, Status } from 'src/config'

import { CheckoutOutputAttributesProps } from 'src/classes/checkout'

import { affiliateToken, isAffiliate, isApplePaySupported, logger } from 'src/utils'

export function absoluteUrl(path: string) {
  const link = document.createElement('a')
  link.href = path
  return link.protocol + '//' + link.host + link.pathname + link.search + link.hash
}

function queryString(obj: CheckoutOutputAttributesProps, prefix?: string): string {
  const str = []
  for (let p in obj) {
    if (obj.hasOwnProperty(p)) {
      const k = prefix ? prefix + '[' + p + ']' : p,
        v = obj[p]
      if (k != null && k !== '' && v != null && (v !== '' || v === 0) && typeof v != 'function') {
        // Remove some Checkout URL specific parameters we don't wish to pass
        if (
          k !== 'closeCallback' &&
          k !== 'successCallback' &&
          k !== 'loadCallback' &&
          k !== 'method' &&
          k !== 'override'
        ) {
          str.push(typeof v == 'object' ? queryString(v, k) : encodeURIComponent(k) + '=' + encodeURIComponent(v))
        }
      }
    }
  }

  return str.join('&')
}

// Auto-apply coupon if paddle_coupon=X
export function getUrlCoupon() {
  if (typeof urlParam(URL_PARAMS.PADDLE_COUPON) != 'undefined' && urlParam(URL_PARAMS.PADDLE_COUPON) !== '') {
    return urlParam(URL_PARAMS.PADDLE_COUPON)
  } else {
    return false
  }
}

export function validateCheckoutOrigin(origin: string) {
  const regex = /^https:\/\/[a-zA-Z0-9-_]+\.paddle\.(?:com|dev|local)$/g
  return regex.test(origin)
}

export function sanitizeRedirectUrl(redirectUrl?: string | number): string {
  const preventRedirectUrl = '#!'
  const XSS_ARRAY_CHECK = [';', '\b', '\f', '\n', '\r', '\t', '\v', '\0', '\x09', '\x0d', '\x0a']

  if (typeof redirectUrl !== 'string') {
    return preventRedirectUrl
  }
  const escapedRedirectUrl = escape(redirectUrl)

  // eslint-disable-next-line no-script-url
  if (!redirectUrl.length || redirectUrl.substring(0, 11) === 'javascript:') {
    return preventRedirectUrl
  }

  for (let i = 0; i < XSS_ARRAY_CHECK.length; i++) {
    if (redirectUrl.indexOf(XSS_ARRAY_CHECK[i]) > -1 || escapedRedirectUrl.indexOf(XSS_ARRAY_CHECK[i]) > -1) {
      return preventRedirectUrl
    }
  }
  return redirectUrl
}
export function urlParam(param: string) {
  const vars = {}
  window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (_, ...theArgs) {
    const [key, value] = theArgs
    vars[key] = value
    return ''
  })

  // We split any return value at the first #, as this indicates the start of a URL fragment, not query string.
  return vars[param] ? vars[param].split('#')[0] : ''
}

export function buildCheckoutUrl(
  productId?: number | string,
  checkoutQuery: CheckoutOutputAttributesProps = {},
  type: CHECKOUT_TYPE | undefined = undefined,
) {
  let checkoutFullUrl = ''

  checkoutQuery.apple_pay_enabled = isApplePaySupported() ? true : false
  checkoutQuery['paddlejs-version'] = Status.libraryVersion
  checkoutQuery.vendor = Options.vendor
  // CO-2920 : Add UTC Timestamp
  checkoutQuery.checkout_initiated = new Date().getTime()

  // We should append/ decide popup status even if developer is overriding the URL.
  if (type === CHECKOUT_TYPE.POPUP) {
    checkoutQuery.popup = 'true'
    checkoutQuery.paddle_js = 'true'
    checkoutQuery.is_popup = 'true' // @note Hosted Update/Cancel URLs appear to be the only thing that use this parameter name.
  } else {
    delete checkoutQuery.popup

    if (type === CHECKOUT_TYPE.FALLBACK) {
      // Fallback checkouts are both 'popup' and 'popup_window' as we still want popup characteristics, like postMessage and close buttons.
      checkoutQuery.popup = 'true'
      checkoutQuery.paddle_js = 'true'
      checkoutQuery.popup_window = 'true'
      checkoutQuery.is_popup = 'true' // @note Hosted Update/Cancel URLs appear to be the only thing that use this parameter name.

      if (Options.sdk) {
        checkoutQuery.display_mode = DISPLAY_MODE.SDK
      }
    }
  }
  // If coupon is applied in URL apply it on checkout open.
  const urlCoupon = getUrlCoupon()
  if (urlCoupon) {
    checkoutQuery.coupon = urlCoupon
  }

  // Check if this is an affiliate checkout, if so handle it differently.
  // Only use the affiliate checkout URL if vendor isn't using an override checkout.
  if (isAffiliate() && (typeof checkoutQuery.override == 'undefined' || checkoutQuery.override.length === 0)) {
    // Buffer the checkout URL through the affiliate system.
    checkoutFullUrl =
      'https://a.paddle.com/checkout/' +
      affiliateToken() +
      '/?type=product&product_id=' +
      productId +
      '&' +
      queryString(checkoutQuery)

    // Add an additional item for the 'data-success' parameter for affiliate checkouts
    if (typeof checkoutQuery.success != 'undefined' && checkoutQuery.success !== '') {
      checkoutQuery.affiliate_success = absoluteUrl(checkoutQuery.success)
    }
  } else {
    // Check if developer is passing in an 'override' checkout URL
    if (
      typeof checkoutQuery.override != 'undefined' &&
      checkoutQuery.override !== '' &&
      checkoutQuery.override != null
    ) {
      let overrideGlue = ''
      // If they are passing override, we use that...
      if (checkoutQuery.override.indexOf('?') <= -1) {
        overrideGlue = '/?'
      } else {
        overrideGlue = '&'
      }

      checkoutFullUrl = checkoutQuery.override + overrideGlue + queryString(checkoutQuery)
      // Paylinks that are passed via the override URL should use the new CCS v2 version.
      if (checkIsPaylinkURL(checkoutFullUrl)) {
        checkoutFullUrl = getCheckoutFrontEndURL(checkoutFullUrl)
      }
    } else {
      // If not passing override, we build a checkout URL based on the default Base URL.
      checkoutFullUrl = Environment.defaults().checkoutBase + productId + '/?' + queryString(checkoutQuery)
      checkoutFullUrl = getCheckoutFrontEndURL(checkoutFullUrl)
    }
  }

  logger.log('Built checkout URL: ' + checkoutFullUrl)
  return checkoutFullUrl
}

const getCheckoutFrontEndURL = (checkoutCreateServiceUrl: string) =>
  Environment.defaults().checkoutFrontEndBase + '/paddlejs?ccsURL=' + checkoutCreateServiceUrl

const checkIsPaylinkURL = (url: string) => {
  let isPaylink = false
  const checkoutCreateDomains = [
    DEV_CREATE_CHECKOUT_DOMAIN,
    STAGING_CREATE_CHECKOUT_DOMAIN,
    SANDBOX_CREATE_CHECKOUT_DOMAIN,
    PRODUCTION_CREATE_CHECKOUT_DOMAIN,
  ]

  checkoutCreateDomains.forEach((createUrl) => {
    if (url.indexOf(createUrl) !== -1 && url.indexOf('/checkout/custom') !== -1) {
      isPaylink = true
      return
    }
  })

  return isPaylink
}

export const attachCheckoutUrl = (windowIFrame: HTMLIFrameElement, url: string) => {
  windowIFrame.src = url
}
