import { CHECKOUT_METHOD, CHECKOUT_EVENTS, ACTIONS } from 'src/constants'

import { Options } from 'src/config'

import { Checkout } from 'src/classes/checkout'

import {
  validateCheckoutOrigin,
  closeCheckout,
  completeCheckout,
  isMobile,
  analyticsDefaults,
  hideLoading,
  logger,
  displayUpsell,
  removeUpsells,
  freeze,
} from 'src/utils'
import { EventProps } from 'src/utils/analytics'
import { isWideOverlayFn } from 'src/utils/upsell'

export function shouldSkipSendingEvents(): boolean {
  return !!(Options.enableConcurrentVersions && !Checkout.isOpen)
}

export const _messageListener = function (message: MessageEvent) {
  /*
					if(_options.sdk) {
						logger.log('SDK Post Message Output: '+JSON.stringify(message)+' / '+JSON.stringify(message.data));
					}
					*/
  if (
    typeof message?.data === 'object' &&
    message.data?.event_name &&
    [CHECKOUT_EVENTS.CHECKOUT_LOADED, CHECKOUT_EVENTS.CHECKOUT_FAILED].includes(message.data.event_name)
  ) {
    Checkout.isOpen = true
  }

  // If the origin is not coming from a paddle domain we do nothing.
  if (!validateCheckoutOrigin(message.origin) || shouldSkipSendingEvents()) {
    return
  }

  if (typeof message.data == 'object') {
    let callback_data
    if (typeof message.data.callback_data == 'undefined') {
      callback_data = {}
    } else {
      callback_data = message.data.callback_data
    }

    if (message.data.action === ACTIONS.CLOSE) {
      closeCheckout(callback_data)
      removeUpsells()
    } else if (message.data.action === ACTIONS.COMPLETE) {
      completeCheckout(callback_data)
    } else if (message.data.action === ACTIONS.FAILED) {
      const eventData = {
        event: CHECKOUT_EVENTS.CHECKOUT_FAILED,
        eventData: {},
        checkoutData: window._activeCheckout,
        campaignData: analyticsDefaults(),
      }
      fireEvent(eventData as EventProps)
      return
    }

    if (
      message.data.action === ACTIONS.EVENT ||
      message.data.action === ACTIONS.CLOSE ||
      message.data.action === ACTIONS.COMPLETE
    ) {
      // Fire global event to vendor (if subscribed)
      if (message.data.action === ACTIONS.EVENT) {
        if (message.data.event === CHECKOUT_EVENTS.CHECKOUT_REMOVE_SPINNER) {
          hideLoading()
        }

        if (message.data.event_name !== CHECKOUT_EVENTS.CHECKOUT_PING_SIZE) {
          let globalEventData = {
            event: message.data.event_name,
            eventData: callback_data,
            checkoutData: window._activeCheckout,
            campaignData: analyticsDefaults(),
          }
          fireEvent(globalEventData as EventProps)
        }
      } else {
        let eventName
        if (message.data.action === ACTIONS.CLOSE) {
          eventName = CHECKOUT_EVENTS.CHECKOUT_CLOSE
        } else if (message.data.action === ACTIONS.COMPLETE) {
          eventName = CHECKOUT_EVENTS.CHECKOUT_COMPLETE
        }
        let globalEventData = {
          event: eventName,
          eventData: callback_data,
          checkoutData: window._activeCheckout,
          campaignData: analyticsDefaults(),
        }
        fireEvent(globalEventData as EventProps)
      }
    }

    if (message.data.action === ACTIONS.EVENT) {
      // Map the events we expect from the checkout to avoid noise from other postMessage firers that might be calling their message "event"
      // Also allows us to map data to analytics properties.
      if (message && message.data && message.data.event_name) {
        logger.log('Checkout fired message: ' + message.data.event_name)

        if (message.data.event_name === CHECKOUT_EVENTS.CHECKOUT_LOADED) {
          if (
            typeof window._activeCheckout != 'undefined' &&
            window._activeCheckout.method !== CHECKOUT_METHOD.INLINE
          ) {
            // Freeze viewport to frame on mobile.
            if (isMobile()) {
              freeze(1.0, 'pf_' + window._activeCheckout.product)
            }

            // Remote checkout has fired a 'Loaded' event. We can close any loading spinners.
            hideLoading()

            // Display Upsell **Beta**
            // @note Only open the upsell if this isn't an upsell button click.
            if (
              typeof window._activeCheckout != 'undefined' &&
              typeof window._activeCheckout.isUpsell == 'undefined' &&
              window._activeCheckout.upsell
            ) {
              displayUpsell(window._activeCheckout.upsell)
            }
          }
          if (typeof window._activeCheckout != 'undefined') {
            if (typeof window._activeCheckout.loadCallback === 'function') {
              window._activeCheckout.loadCallback()
            } else if (typeof window[window._activeCheckout.loadCallback as any] === 'function') {
              const loadCallback = window[window._activeCheckout.loadCallback as any] as any
              loadCallback()
            }
          }
        } else if (message.data.event_name === CHECKOUT_EVENTS.CHECKOUT_PING_SIZE) {
          // The checkout sends us it's height every second or so, use it to update the height of the on-page iframe
          // if this is an 'inline' checkout.
          if (message.data.callback_data && message.data.callback_data.height !== '') {
            if (typeof window._activeCheckout.frameTarget != 'undefined') {
              const iFrame = document
                .getElementsByClassName(window._activeCheckout.frameTarget)[0]
                .getElementsByTagName('iframe')
              if (iFrame.length > 0) {
                const newFrameHeight = parseInt(message.data.callback_data.height) + 45
                iFrame[0].setAttribute('height', `${newFrameHeight}`)
              }
            }
          }
        } else if (message.data.event_name === CHECKOUT_EVENTS.UPSELL_DIALOG_POSITION) {
          // Reposition Upsell dialog for wide overlay
          if (
            typeof window._activeCheckout != 'undefined' &&
            typeof window._activeCheckout.isUpsell == 'undefined' &&
            window._activeCheckout.upsell
          ) {
            const callback_data = message.data.callback_data

            const checkoutMethod = isWideOverlayFn() ? CHECKOUT_METHOD.WIDE_OVERLAY : window._activeCheckout.method

            const useAsWideOverlayExperiment = Boolean(callback_data.layout !== checkoutMethod)
            if (callback_data.layout === CHECKOUT_METHOD.WIDE_OVERLAY) {
              window._cfeProps = { ...callback_data, useAsWideOverlayExperiment }
              displayUpsell(
                window._activeCheckout.upsell,
                useAsWideOverlayExperiment,
                callback_data.from_top,
                callback_data.cta_background_color,
              )
            }
          }
        }
      }
    }
  }
}

// Listen for messages from the checkout
export function listen(): void {
  window.addEventListener('message', _messageListener, false)
}

export function fireEvent(eventData: EventProps) {
  if (typeof Options.eventCallback == 'function') {
    Options.eventCallback(eventData)
  }
}
