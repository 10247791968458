import { ENVIRONMENTS } from 'src/constants/environment'

const imagesBaseURL = {
  [ENVIRONMENTS.PRODUCTION]: 'https://cdn.paddle.com/paddle/assets/images',
  [ENVIRONMENTS.SANDBOX]: 'https://sandbox-cdn.paddle.com/paddle/assets/images',
  [ENVIRONMENTS.STAGING]: 'https://staging-cdn.paddle.dev/paddle/assets/images',
  [ENVIRONMENTS.DEVELOPMENT]: 'https://development-cdn.paddle.dev/paddle/assets/images',
  [ENVIRONMENTS.LOCAL]: '/assets/css',
}
const cssBaseURL = {
  [ENVIRONMENTS.PRODUCTION]: 'https://cdn.paddle.com/paddle/assets/css',
  [ENVIRONMENTS.SANDBOX]: 'https://sandbox-cdn.paddle.com/paddle/assets/css',
  [ENVIRONMENTS.STAGING]: 'https://staging-cdn.paddle.dev/paddle/assets/css',
  [ENVIRONMENTS.DEVELOPMENT]: 'https://development-cdn.paddle.dev/paddle/assets/css',
  [ENVIRONMENTS.LOCAL]: '/assets/css',
}

export const errorLinks = {
  [ENVIRONMENTS.PRODUCTION]: 'https://cdn.paddle.com/paddle/error.html',
  [ENVIRONMENTS.SANDBOX]: 'https://sandbox-cdn.paddle.com/paddle/error.html',
  [ENVIRONMENTS.STAGING]: 'https://staging-cdn.paddle.dev/paddle/error.html',
  [ENVIRONMENTS.DEVELOPMENT]: 'https://development-cdn.paddle.dev/paddle/error.html',
  [ENVIRONMENTS.LOCAL]: 'http://localhost:8081/error.html',
}

export const getResource = (environment: ENVIRONMENTS) => {
  const env = Object.values(ENVIRONMENTS).indexOf(environment) > -1 ? environment : ENVIRONMENTS.PRODUCTION

  return {
    PADDLE_CSS_FILE: cssBaseURL[env] + '/paddle.css',
    ANIMATION_CSS_FILE: cssBaseURL[env] + '/animate.css',
    VENDORS_URL: 'https://vendors.paddle.com/download/product/',
    CLOSE_IMAGE_DARK: imagesBaseURL[env] + '/close-dark.png',
    CLOSE_IMAGE: imagesBaseURL[env] + '/close',
    LOADING_GIF: imagesBaseURL[env] + '/loading.gif',
    HEALTH_CHECK_GIF: imagesBaseURL[env] + '/health-check.gif',
  }
}

export const LOCAL_CREATE_CHECKOUT_DOMAIN = 'https://checkout-service.paddle.local/create'

export const LOCAL_URLS = {
  checkoutBase: LOCAL_CREATE_CHECKOUT_DOMAIN + '/checkout/product/',
  checkoutFrontEndBase: 'https://local-buy.paddle.com',
  subscriptionManagementFrontEndBase: 'https://local-subscription-management.paddle.com',
  pricesApi: 'https://local-buy.paddle.com/product/api/1.0/prices',
  dataApi: 'https://local-buy.paddle.com/product/api/1.0/data',
  orderApi: 'https://local-buy.paddle.com/product/api/1.0/order',
  audienceApi: 'https://local-buy.paddle.com/product/api/1.0/audience/{vendor_id}/add',
  userHistoryApi: 'https://local-buy.paddle.com/api/2.0/user/history',
}

export const DEV_CREATE_CHECKOUT_DOMAIN = 'https://development-checkout-service.paddle.dev/create'
export const DEV_URLS = {
  checkoutBase: DEV_CREATE_CHECKOUT_DOMAIN + '/checkout/product/',
  checkoutFrontEndBase: 'https://development-buy.paddle.dev',
  subscriptionManagementFrontEndBase: 'https://development-subscription-management.paddle.dev',
  pricesApi: 'https://development-checkout.paddle.dev/api/1.0/prices',
  dataApi: 'https://development-checkout.paddle.dev/api/1.0/data',
  orderApi: 'https://development-checkout.paddle.dev/api/1.0/order',
  audienceApi: 'https://development-checkout.paddle.dev/api/1.0/audience/{vendor_id}/add',
  userHistoryApi: 'https://development-checkout.paddle.dev/api/2.0/user/history',
}

export const STAGING_CREATE_CHECKOUT_DOMAIN = 'https://staging-checkout-service.paddle.dev/create'
export const STAGING_URLS = {
  checkoutBase: STAGING_CREATE_CHECKOUT_DOMAIN + '/checkout/product/',
  checkoutFrontEndBase: 'https://staging-buy.paddle.dev',
  subscriptionManagementFrontEndBase: 'https://staging-subscription-management.paddle.dev',
  pricesApi: 'https://staging-checkout.paddle.dev/api/1.0/prices',
  dataApi: 'https://staging-checkout.paddle.dev/api/1.0/data',
  orderApi: 'https://staging-checkout.paddle.dev/api/1.0/order',
  audienceApi: 'https://staging-checkout.paddle.dev/api/1.0/audience/{vendor_id}/add',
  userHistoryApi: 'https://staging-checkout.paddle.dev/api/2.0/user/history',
}

export const SANDBOX_CREATE_CHECKOUT_DOMAIN = 'https://sandbox-checkout-service.paddle.com/create'
export const SANDBOX_URLS = {
  checkoutBase: SANDBOX_CREATE_CHECKOUT_DOMAIN + '/checkout/product/',
  checkoutFrontEndBase: 'https://sandbox-buy.paddle.com',
  subscriptionManagementFrontEndBase: 'https://sandbox-subscription-management.paddle.com',
  pricesApi: 'https://sandbox-checkout.paddle.com/api/1.0/prices',
  dataApi: 'https://sandbox-checkout.paddle.com/api/1.0/data',
  orderApi: 'https://sandbox-checkout.paddle.com/api/1.0/order',
  audienceApi: 'https://sandbox-checkout.paddle.com/api/1.0/audience/{vendor_id}/add',
  userHistoryApi: 'https://sandbox-checkout.paddle.com/api/2.0/user/history',
}

export const PRODUCTION_CREATE_CHECKOUT_DOMAIN = 'https://checkout-service.paddle.com/create'
export const PRODUCTION_URLS = {
  checkoutBase: PRODUCTION_CREATE_CHECKOUT_DOMAIN + '/checkout/product/',
  checkoutFrontEndBase: 'https://buy.paddle.com',
  subscriptionManagementFrontEndBase: 'https://subscription-management.paddle.com',
  pricesApi: 'https://checkout.paddle.com/api/1.0/prices',
  dataApi: 'https://checkout.paddle.com/api/1.0/data',
  orderApi: 'https://checkout.paddle.com/api/1.0/order',
  audienceApi: 'https://checkout.paddle.com/api/1.0/audience/{vendor_id}/add',
  userHistoryApi: 'https://checkout.paddle.com/api/2.0/user/history',
}
