import { DATA_ATTRIBUTES } from 'src/constants'

export const defaultConsentMessage =
  '<var vendorname=""></var> may send me product updates and offers via email. It is possible to opt-out at any time.'

export function getConsentMessageWithVendor(vendorName: string) {
  return defaultConsentMessage.replace(/<var(.*?)var>/, vendorName)
}

export function loadConsentMessages(): void {
  const audiencePopups = document.querySelectorAll('.paddle-popup')

  // If the element is not created yet, it means that the document is still not loaded
  // so we're adding this check to prevent race conditions (or, to be more precise, to mitigate them)
  if (audiencePopups.length > 0) {
    for (let i = audiencePopups.length - 1; i >= 0; i--) {
      const popup = audiencePopups[i]
      popup.removeAttribute('aria-busy')

      const marketingConsent = popup.querySelector('.paddle-popup-checkbox')

      if (marketingConsent) {
        const marketingConsentLabel = marketingConsent.querySelector('.paddle-popup-checkbox-label')
        //@ts-ignore
        const labelVendorName = marketingConsentLabel.getAttribute(DATA_ATTRIBUTES.DATA_VENDOR_NAME)

        marketingConsent.removeAttribute('style')
        //@ts-ignore
        marketingConsentLabel.innerHTML = getConsentMessageWithVendor(labelVendorName)
      }
    }
  }
}
