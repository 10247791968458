import {
  ANALYTICS_EVENT,
  getResource,
  LOG_LEVEL,
  AMOUNT_TYPE,
  POPUP_METHOD,
  POPUP_TYPE,
  CLASSES,
  THEME,
  DATA_ATTRIBUTES,
} from 'src/constants'
import { BTN_ATTRIBUTES_KEYS } from 'src/constants/html-components'

import { Environment, Status } from 'src/config'

import { Checkout, CheckoutInputAttributesProps } from 'src/classes/checkout'

import {
  logger,
  getProductPrice,
  analyticsEvent,
  showPopup,
  audiencePopup,
  sanitizeRedirectUrl,
  isTruthy,
  isFalsy,
} from 'src/utils'
import { SubscribeAudienceResponseProps } from 'src/utils/audience'

// Show element.
export function show(element: Element) {
  removeClass(element, CLASSES.PADDLE_HIDDEN)
  addClass(element, CLASSES.PADDLE_VISIBLE)
}

// Hide element.
export function hide(element: Element) {
  removeClass(element, CLASSES.PADDLE_VISIBLE)
  addClass(element, CLASSES.PADDLE_HIDDEN)
}

export function each(className: string, callback?: Function) {
  const elements: HTMLCollectionOf<Element> = document.getElementsByClassName(className) as HTMLCollectionOf<Element> // a live nodeList

  for (let i = 0; i < elements.length; i++) {
    const thisElement = elements[i]

    if (typeof callback === 'function') {
      callback(thisElement)
    } else {
      throw new Error('each(className, function() {... requires the callback argument to be of type Function')
    }

    // Might need to reverse the order in which we loop through, unsure. See:
    // http://stackoverflow.com/questions/15843581/how-to-corectly-iterate-through-getelementsbyclassname
  }
}

// nl2br (php) equivilant function.
export function nl2br(str: string, is_xhtml?: boolean) {
  const breakTag = is_xhtml || typeof is_xhtml == 'undefined' ? '<br />' : '<br>'
  return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2')
}

export function hasClass(el: Element | HTMLElement, className: string) {
  if (el.classList) {
    return el.classList.contains(className)
  } else {
    return !!el.className.match(new RegExp('(\\s|^)' + className + '(\\s|$)'))
  }
}

export function addClass(el: HTMLElement | Element, className: string) {
  if (el.classList) {
    el.classList.add(className)
  } else if (!hasClass(el, className)) {
    el.className += ' ' + className
  }
}

export function removeClass(el: HTMLElement | Element, className: string) {
  if (el.classList) {
    el.classList.remove(className)
  } else if (hasClass(el, className)) {
    const reg = new RegExp('(\\s|^)' + className + '(\\s|$)')
    el.className = el.className.replace(reg, ' ')
  }
}

// Animation Library
export function addAnimationStylesheet(): void {
  const env = Environment.get()
  if (!Status.loadedAnimationStylesheet) {
    const head = document.getElementsByTagName('head')[0]
    const link = document.createElement('link')
    link.rel = 'stylesheet'
    link.type = 'text/css'
    link.href = getResource(env).ANIMATION_CSS_FILE
    link.media = 'all'
    head.appendChild(link)

    Status.loadedAnimationStylesheet = true
  }
}

export function addButtonStylesheet(): void {
  const env = Environment.get()
  if (!Status.loadedButtonStylesheet) {
    const head = document.getElementsByTagName('head')[0]
    const link = document.createElement('link')
    link.rel = 'stylesheet'
    link.type = 'text/css'
    link.href = getResource(env).PADDLE_CSS_FILE
    link.media = 'all'
    head.appendChild(link)

    Status.loadedButtonStylesheet = true
  }
}

export function addButtonTheme(buttonElement: HTMLElement, theme?: string) {
  if (theme !== 'none') {
    addClass(buttonElement, CLASSES.PADDLE_STYLED_BUTTON)

    if (theme === THEME.GREEN) {
      addClass(buttonElement, CLASSES.GREEN)
    } else if (theme === THEME.LIGHT) {
      addClass(buttonElement, CLASSES.LIGHT)
    } else if (theme === THEME.DARK) {
      addClass(buttonElement, CLASSES.DARK)
    }
  }
}

function quantityTypeHelper(value: string | null, defaultVal: number = 1): number {
  return value !== null && isNaN(Number(value)) ? defaultVal : Number(value)
}

export function attribute(
  attributesObject: object,
  attributesObjectKey: string,
  buttonElement: HTMLElement,
  attributeName: string,
  attributeDefault: boolean | string | null = false,
) {
  const attributeValue =
    buttonElement.getAttribute(attributeName) !== '' && buttonElement.getAttribute(attributeName) != null
      ? buttonElement.getAttribute(attributeName)
      : attributeDefault

  if (attributeValue) {
    attributesObject[attributesObjectKey] = attributeValue
  }

  return attributesObject
}

interface ButtonAttributesProps extends CheckoutInputAttributesProps {
  theme?: THEME
}
export function getButtonAttributes(buttonElement: HTMLElement | HTMLButtonElement) {
  // Create a friendly object of recognised attributes from the button (named correctly)
  const buttonAttributes: ButtonAttributesProps = {
    product: buttonElement[BTN_ATTRIBUTES_KEYS.PRODUCT],
  }
  attribute(buttonAttributes, BTN_ATTRIBUTES_KEYS.THEME, buttonElement, DATA_ATTRIBUTES.DATA_THEME, THEME.GREEN)
  attribute(buttonAttributes, BTN_ATTRIBUTES_KEYS.PRODUCT, buttonElement, DATA_ATTRIBUTES.DATA_PRODUCT)
  attribute(buttonAttributes, BTN_ATTRIBUTES_KEYS.METHOD, buttonElement, DATA_ATTRIBUTES.DATA_METHOD)
  attribute(buttonAttributes, BTN_ATTRIBUTES_KEYS.TYPE, buttonElement, DATA_ATTRIBUTES.DATA_TYPE)
  attribute(buttonAttributes, BTN_ATTRIBUTES_KEYS.HIDE_TAX_LINK, buttonElement, DATA_ATTRIBUTES.DATA_HIDE_TAX_LINK)

  // Go through each of the data-* parameters and add them to our attributes for the checkout.
  // Checkout Success Callback
  attribute(
    buttonAttributes,
    BTN_ATTRIBUTES_KEYS.SUCCESS_CALLBACK,
    buttonElement,
    DATA_ATTRIBUTES.DATA_SUCCESS_CALLBACK,
    null,
  )

  // Checkout Load Callback
  attribute(
    buttonAttributes,
    BTN_ATTRIBUTES_KEYS.LOAD_CALLBACK,
    buttonElement,
    DATA_ATTRIBUTES.DATA_LOAD_CALLBACK,
    null,
  )

  // Checkout Close Callback
  attribute(
    buttonAttributes,
    BTN_ATTRIBUTES_KEYS.CLOSE_CALLBACK,
    buttonElement,
    DATA_ATTRIBUTES.DATA_CLOSE_CALLBACK,
    null,
  )

  // Success Redirect
  attribute(buttonAttributes, BTN_ATTRIBUTES_KEYS.SUCCESS, buttonElement, DATA_ATTRIBUTES.DATA_SUCCESS)

  // Price Override
  attribute(buttonAttributes, BTN_ATTRIBUTES_KEYS.PRICE, buttonElement, DATA_ATTRIBUTES.DATA_PRICE, '')

  // Price Override Auth
  attribute(buttonAttributes, BTN_ATTRIBUTES_KEYS.AUTH, buttonElement, DATA_ATTRIBUTES.DATA_AUTH, '')

  // Trial Days
  attribute(buttonAttributes, BTN_ATTRIBUTES_KEYS.TRIAL_DAYS, buttonElement, DATA_ATTRIBUTES.DATA_TRIAL_DAYS, '')

  // Trial Days Auth
  attribute(
    buttonAttributes,
    BTN_ATTRIBUTES_KEYS.TRIAL_DAYS_AUTH,
    buttonElement,
    DATA_ATTRIBUTES.DATA_TRIAL_DAYS_AUTH,
    '',
  )

  // Guest Display mode theme

  attribute(
    buttonAttributes,
    BTN_ATTRIBUTES_KEYS.DISPLAY_MODE_THEME,
    buttonElement,
    DATA_ATTRIBUTES.DATA_DISPLAY_MODE_THEME,
    '',
  )

  // Marketing Consent
  if (buttonElement.hasAttribute(DATA_ATTRIBUTES.DATA_MARKETING_CONSENT)) {
    const isMarketingConsentEnabled = isTruthy(buttonElement.getAttribute(DATA_ATTRIBUTES.DATA_MARKETING_CONSENT))

    buttonAttributes.marketingConsent = isMarketingConsentEnabled ? '1' : '0'
  }

  // Email
  attribute(buttonAttributes, BTN_ATTRIBUTES_KEYS.EMAIL, buttonElement, DATA_ATTRIBUTES.DATA_EMAIL, '')

  // Country
  attribute(buttonAttributes, BTN_ATTRIBUTES_KEYS.COUNTRY, buttonElement, DATA_ATTRIBUTES.DATA_COUNTRY, '')

  // Postcode
  attribute(buttonAttributes, BTN_ATTRIBUTES_KEYS.POSTCODE, buttonElement, DATA_ATTRIBUTES.DATA_POSTCODE, '')

  // Passthrough
  attribute(buttonAttributes, BTN_ATTRIBUTES_KEYS.PASSTHROUGH, buttonElement, DATA_ATTRIBUTES.DATA_PASSTHROUGH, '')

  if (buttonAttributes[BTN_ATTRIBUTES_KEYS.PASSTHROUGH]) {
    buttonAttributes[BTN_ATTRIBUTES_KEYS.PASSTHROUGH] = decodeURIComponent(
      (buttonAttributes[BTN_ATTRIBUTES_KEYS.PASSTHROUGH] as string) || '',
    )
  }

  // Upsell Passthrough
  attribute(
    buttonAttributes,
    BTN_ATTRIBUTES_KEYS.UPSELL_PASSTHROUGH,
    buttonElement,
    DATA_ATTRIBUTES.DATA_UPSELL_PASSTHROUGH,
    false,
  )

  if (buttonAttributes[BTN_ATTRIBUTES_KEYS.UPSELL_PASSTHROUGH]) {
    buttonAttributes[BTN_ATTRIBUTES_KEYS.UPSELL_PASSTHROUGH] = decodeURIComponent(
      buttonAttributes[BTN_ATTRIBUTES_KEYS.UPSELL_PASSTHROUGH] || '',
    )
  }

  // Coupon
  attribute(buttonAttributes, BTN_ATTRIBUTES_KEYS.COUPON, buttonElement, DATA_ATTRIBUTES.DATA_COUPON, '')

  // Locale
  attribute(buttonAttributes, BTN_ATTRIBUTES_KEYS.LOCALE, buttonElement, DATA_ATTRIBUTES.DATA_LOCALE, '')

  // Quantity
  attribute(buttonAttributes, BTN_ATTRIBUTES_KEYS.QUANTITY, buttonElement, DATA_ATTRIBUTES.DATA_QUANTITY, '')

  // Custom Message
  attribute(buttonAttributes, BTN_ATTRIBUTES_KEYS.MESSAGE, buttonElement, DATA_ATTRIBUTES.DATA_MESSAGE, '')

  // Referrer
  attribute(buttonAttributes, BTN_ATTRIBUTES_KEYS.REFERRING_DOMAIN, buttonElement, DATA_ATTRIBUTES.DATA_REFERRER, '')

  // Title
  attribute(buttonAttributes, BTN_ATTRIBUTES_KEYS.TITLE, buttonElement, DATA_ATTRIBUTES.DATA_DISABLE_TITLE, '')

  // Disable Logout
  attribute(
    buttonAttributes,
    BTN_ATTRIBUTES_KEYS.DISABLE_LOGOUT,
    buttonElement,
    DATA_ATTRIBUTES.DATA_DISABLE_LOGOUT,
    '',
  )

  // Checkout Upsell Product
  // @note This is the product that is UPSOLD upon checkout open
  attribute(buttonAttributes, BTN_ATTRIBUTES_KEYS.UPSELL, buttonElement, DATA_ATTRIBUTES.DATA_UPSELL, '')

  // Upsell text.
  attribute(buttonAttributes, BTN_ATTRIBUTES_KEYS.UPSELL_TEXT, buttonElement, DATA_ATTRIBUTES.DATA_UPSELL_TEXT, false)

  // Upsell title.
  attribute(buttonAttributes, BTN_ATTRIBUTES_KEYS.UPSELL_TITLE, buttonElement, DATA_ATTRIBUTES.DATA_UPSELL_TITLE, false)

  // Upsell cta.
  attribute(
    buttonAttributes,
    BTN_ATTRIBUTES_KEYS.UPSELL_ACTION,
    buttonElement,
    DATA_ATTRIBUTES.DATA_UPSELL_ACTION,
    false,
  )

  // Upsell coupon.
  attribute(buttonAttributes, BTN_ATTRIBUTES_KEYS.UPSELL_COUPON, buttonElement, DATA_ATTRIBUTES.DATA_UPSELL_COUPON, '')

  // Is an Upsell button click?
  attribute(buttonAttributes, BTN_ATTRIBUTES_KEYS.IS_UPSELL, buttonElement, DATA_ATTRIBUTES.DATA_UPSELL_BUTTON, false)

  // Allow Quantity Changing
  // Don't use Button.attribute() for this one.
  if (
    buttonElement.getAttribute(DATA_ATTRIBUTES.DATA_ALLOW_QUANTITY) !== 'undefined' &&
    buttonElement.getAttribute(DATA_ATTRIBUTES.DATA_ALLOW_QUANTITY) !== null
  ) {
    if (isFalsy(buttonElement.getAttribute(DATA_ATTRIBUTES.DATA_ALLOW_QUANTITY))) {
      buttonAttributes.allowQuantity = '0'
    } else {
      buttonAttributes.allowQuantity = '1'
    }
  }

  // URL Override
  attribute(buttonAttributes, BTN_ATTRIBUTES_KEYS.OVERRIDE, buttonElement, DATA_ATTRIBUTES.DATA_OVERRIDE, '')

  // Custom Data
  attribute(buttonAttributes, BTN_ATTRIBUTES_KEYS.CUSTOM_DATA, buttonElement, DATA_ATTRIBUTES.DATA_CUSTOM_DATA, '')

  return buttonAttributes
}

export function loadButtons() {
  ready(_onLoadButtonsReady)
}

export const _onLoadButtonsReady = () => {
  let buttonCounter = 0
  each('paddle_button', function (buttonElement: HTMLElement) {
    const paddleVersion: string = buttonElement.getAttribute(DATA_ATTRIBUTES.DATA_PADDLE_VERSION) ?? 'classic'
    if (paddleVersion !== 'classic') {
      return
    }
    // Has this button already been initialised?
    const _buttonHasInit = buttonElement.getAttribute(DATA_ATTRIBUTES.DATA_INIT) === 'true'

    // If this isn't the first init, remove any Paddle click handlers, buttonAttributes is cached, this will force it to fetch fresh values.
    if (_buttonHasInit) {
      // This method of cloning the element means we do not have to keep track of handlers we've added.
      const buttonClone = buttonElement.cloneNode(true) as HTMLElement
      buttonElement.parentNode?.replaceChild(buttonClone, buttonElement)
      buttonElement = buttonClone
    }

    // Get the Button Attributes for theme etc...
    const buttonAttributes = getButtonAttributes(buttonElement)

    // Apply theme to the button if required.
    addButtonTheme(buttonElement, buttonAttributes.theme)

    // Add an attribute indicating the button has a handler
    buttonElement.setAttribute(DATA_ATTRIBUTES.DATA_INIT, 'true')

    // On click, we should open a checkout with friendly attributes object from above...
    buttonElement.addEventListener('click', function (event: Event) {
      // Prevents page from scrolling to top if this is an anchor.
      event.preventDefault()

      // Get latest button attribures on each click.
      const buttonAttributes = getButtonAttributes(buttonElement)
      // asserting here since productId is provided
      Checkout.open(buttonAttributes as CheckoutInputAttributesProps)
    })

    buttonCounter++

    // Send a sensible log message for each button rendered.
    if (buttonAttributes.override) {
      logger.log(
        '[PADDLE CLASSIC] Loaded and initiated checkout button for override URL: ' +
          buttonAttributes.override +
          ' (Paddle Button #' +
          buttonCounter +
          ')',
      )
    } else if (buttonAttributes.product) {
      logger.log(
        '[PADDLE CLASSIC] Loaded and initiated checkout button for product: ' +
          buttonAttributes.product +
          ' (Paddle Button #' +
          buttonCounter +
          ')',
      )
    } else {
      logger.log(
        '[PADDLE CLASSIC] Initiated a checkout button without an override URL or Product. (Paddle Button #' +
          buttonCounter +
          ')',
        LOG_LEVEL.WARNING,
      )
    }
  })

  // Replace paddle-gross with gross price, use productId from self, or fallback to parent if not set.
  each('paddle-gross', function (grossElement: HTMLElement) {
    let productId = grossElement.getAttribute(DATA_ATTRIBUTES.DATA_PRODUCT) || false

    const quantity = quantityTypeHelper(grossElement.getAttribute(DATA_ATTRIBUTES.DATA_QUANTITY))

    if (!productId) {
      productId = (grossElement.parentNode as HTMLElement).getAttribute(DATA_ATTRIBUTES.DATA_PRODUCT) || false
    }

    if (productId) {
      getProductPrice(AMOUNT_TYPE.GROSS, productId, quantity, function (amount: string | boolean) {
        grossElement.innerHTML = amount as string
      })
    }
  })

  each('paddle-tax', function (taxElement: HTMLElement) {
    let productId = taxElement.getAttribute(DATA_ATTRIBUTES.DATA_PRODUCT) || false
    const quantity = quantityTypeHelper(taxElement.getAttribute(DATA_ATTRIBUTES.DATA_QUANTITY))

    if (!productId) {
      productId = (taxElement.parentNode as HTMLElement).getAttribute(DATA_ATTRIBUTES.DATA_PRODUCT) || false
    }

    if (productId) {
      getProductPrice(AMOUNT_TYPE.TAX, productId, quantity, function (amount: string | boolean) {
        taxElement.innerHTML = amount as string
      })
    }
  })

  each('paddle-net', function (netElement: HTMLElement) {
    let productId = netElement.getAttribute(DATA_ATTRIBUTES.DATA_PRODUCT) || false
    // const quantity = netElement.getAttribute(DATA_ATTRIBUTES.DATA_QUANTITY) || 1
    const quantity = quantityTypeHelper(netElement.getAttribute(DATA_ATTRIBUTES.DATA_QUANTITY))

    if (!productId) {
      productId = (netElement.parentNode as HTMLElement).getAttribute(DATA_ATTRIBUTES.DATA_PRODUCT) || false
    }

    if (productId) {
      getProductPrice(AMOUNT_TYPE.NET, productId, quantity, function (amount: string | boolean) {
        netElement.innerHTML = amount as string
      })
    }
  })
}

// loadDownloads() invokes all of the items with 'paddle_download' class on the page.
export function loadDownloads() {
  ready(_onLoadDownloadsReady)
}

export const _onLoadDownloadsReady = () => {
  each(CLASSES.PADDLE_DOWNLOAD, function (buttonElement: HTMLElement | HTMLButtonElement) {
    // Has this button already been initialised?
    const _buttonHasInit = buttonElement.getAttribute(DATA_ATTRIBUTES.DATA_INIT) === 'true'

    if (!_buttonHasInit) {
      buttonElement.setAttribute(DATA_ATTRIBUTES.DATA_INIT, 'true')

      const downloadProductId = buttonElement.getAttribute(DATA_ATTRIBUTES.DATA_DOWNLOAD) || false
      const downloadUrl = buttonElement.getAttribute(DATA_ATTRIBUTES.DATA_DOWNLOAD_URL) || false
      const prompt = buttonElement.getAttribute(DATA_ATTRIBUTES.DATA_DOWNLOAD_PROMPT) !== 'false'

      if (!downloadProductId && !downloadUrl) {
        return false
      }

      let heading = buttonElement.getAttribute(DATA_ATTRIBUTES.DATA_DOWNLOAD_HEADING) || false
      let subHeading = buttonElement.getAttribute(DATA_ATTRIBUTES.DATA_DOWNLOAD_SUBHEADING) || false
      let cta = buttonElement.getAttribute(DATA_ATTRIBUTES.DATA_DOWNLOAD_CTA) || false
      let vendorName = buttonElement.getAttribute(DATA_ATTRIBUTES.DATA_VENDOR_NAME) || ''

      const popupId = audiencePopup({
        vendorName: vendorName,
        triggers: {
          timed: false,
          exitIntent: false,
          scrollDepth: false,
        },
        strings: {
          heading: heading || 'Enter your email to download!',
          subHeading: subHeading || 'Enter your email address to begin the download.',
          cta: cta || 'Download!',
          successMessage: null,
        },
        callback: function (data: SubscribeAudienceResponseProps) {
          if (data.success) {
            startDownload(downloadUrl)
          }
        },
      })

      buttonElement.onclick = function (e) {
        e.preventDefault()
        if (downloadProductId) {
          const productDownloadUrl = getURLFromID(downloadProductId)
          if (productDownloadUrl) {
            if (prompt) {
              startDownloadWithPrompt(productDownloadUrl, downloadProductId, popupId)
            } else {
              startDownload(productDownloadUrl)
            }
          }
        } else if (downloadUrl) {
          if (prompt) {
            startDownloadWithPrompt(downloadUrl, null, popupId)
          } else {
            startDownload(downloadUrl)
          }
        }
      }
    }
    return
  })
}

export function startDownloadWithPrompt(url: string | boolean, productId: string | number | null, popupId: string) {
  logger.log(`[PADDLE CLASSIC] Download Prompt requested. url=${url}, productId=${productId}`)
  showPopup(popupId, POPUP_METHOD.DOWNLOAD, POPUP_TYPE.DOWNLOAD)
}

export function getURLFromID(product_id: string = ''): string | boolean {
  const env = Environment.get()
  if (typeof product_id != 'undefined' && product_id !== '') {
    return getResource(env).VENDORS_URL + product_id
  } else {
    return false
  }
}

export function startDownload(url: string | boolean = '') {
  if (typeof url != 'undefined' && url !== '') {
    logger.log('[PADDLE CLASSIC] Download started.')
    analyticsEvent(ANALYTICS_EVENT.DOWNLOAD)
    window.location = sanitizeRedirectUrl(`${url}`) as any
  } else {
    logger.log('[PADDLE CLASSIC] Unable to start download, no URL specified.', LOG_LEVEL.WARNING)
  }
}

export const ready = (function () {
  let readyList: Record<string, any>,
    DOMContentLoaded: EventListenerOrEventListenerObject,
    class2type = {}
  class2type['[object Boolean]'] = 'boolean'
  class2type['[object Number]'] = 'number'
  class2type['[object String]'] = 'string'
  class2type['[object Function]'] = 'function'
  class2type['[object Array]'] = 'array'
  class2type['[object Date]'] = 'date'
  class2type['[object RegExp]'] = 'regexp'
  class2type['[object Object]'] = 'object'

  const ReadyObj = {
    // Is the DOM ready to be used? Set to true once it occurs.
    isReady: false,
    // A counter to track how many items to wait for before
    // the ready event fires. See #6781
    readyWait: 1,
    // Hold (or release) the ready event
    holdReady: function (hold: boolean) {
      if (hold) {
        ReadyObj.readyWait++
      } else {
        ReadyObj.ready(true)
      }
    },
    // Handle when the DOM is ready
    ready: function (wait: boolean = false): undefined {
      // Either a released hold or an DOMready/load event and not yet ready
      if ((wait === true && !--ReadyObj.readyWait) || (wait !== true && !ReadyObj.isReady)) {
        // Make sure body exists, at least, in case IE gets a little overzealous (ticket #5443).
        if (!document.body) {
          setTimeout(ReadyObj.ready, 1)
          return
        }

        // Remember that the DOM is ready
        ReadyObj.isReady = true
        // If a normal DOM Ready event fired, decrement, and wait if need be
        if (wait !== true && --ReadyObj.readyWait > 0) {
          return
        }
        // If there are functions bound, to execute
        readyList.resolveWith(document, [ReadyObj])

        // Trigger any bound ready events
        //if ( ReadyObj.fn.trigger ) {
        //  ReadyObj( document ).trigger( "ready" ).unbind( "ready" );
        //}
        return
      }
      return
    },

    bindReady: function () {
      if (readyList) {
        return
      }
      readyList = ReadyObj._Deferred()

      // Catch cases where $(document).ready() is called after the
      // browser event has already occurred.
      if (document.readyState === 'complete') {
        // Handle it asynchronously to allow scripts the opportunity to delay ready
        return setTimeout(ReadyObj.ready, 1)
      }

      // Mozilla, Opera and webkit nightlies currently support this event
      if (document.addEventListener) {
        // Use the handy event callback
        document.addEventListener('DOMContentLoaded', DOMContentLoaded, false)
        // A fallback to window.onload, that will always work
        window.addEventListener('load', ReadyObj.ready as any, false)

        // If IE event model is used
      } else if ((document as any).attachEvent) {
        // ensure firing before onload,
        // maybe late but safe also for iframes
        ;(document as any)
          .attachEvent(
            'onreadystatechange',
            DOMContentLoaded,
          )(
            // A fallback to window.onload, that will always work
            document as any,
          )
          .attachEvent('onload', ReadyObj.ready)

        // If IE and not a frame
        // continually check to see if the document is ready
        let toplevel = false

        try {
          toplevel = window.frameElement == null
        } catch (e) {}

        if ((document.documentElement as any).doScroll && toplevel) {
          doScrollCheck()
        }
      }
      return
    },
    _Deferred: function () {
      let // callbacks list
        callbacks: any[] = [],
        // stored [ context , args ]
        fired: 0 | 1 | object[],
        // to avoid firing when already doing so
        firing: number,
        // flag to know if the deferred has been cancelled
        cancelled: number,
        // the deferred itself
        deferred = {
          // done( f1, f2, ...)
          done: function () {
            if (!cancelled) {
              let args = arguments,
                i,
                length,
                elem,
                type,
                _fired
              if (fired) {
                _fired = fired
                fired = 0
              }
              for (i = 0, length = args.length; i < length; i++) {
                elem = args[i]
                type = ReadyObj.type(elem)
                if (type === 'array') {
                  deferred.done.apply(deferred, elem)
                } else if (type === 'function') {
                  callbacks.push(elem)
                }
              }
              if (_fired) {
                deferred.resolveWith(_fired[0], _fired[1])
              }
            }
            return this
          },

          // resolve with given context and args
          resolveWith: function (context: Record<string, any>, args: Record<string, any>) {
            if (!cancelled && !fired && !firing) {
              // make sure args are available (#8421)
              args = args || []
              firing = 1
              try {
                while (callbacks[0]) {
                  callbacks.shift().apply(context, args) //shifts a callback, and applies it to document
                }
              } finally {
                fired = [context, args]
                firing = 0
              }
            }
            return this
          },

          // resolve with this as context and given arguments
          resolve: function () {
            deferred.resolveWith(this, arguments)
            return this
          },

          // Has this deferred been resolved?
          isResolved: function () {
            return !!(firing || fired)
          },

          // Cancel
          cancel: function () {
            cancelled = 1
            callbacks = []
            return this
          },
        }

      return deferred
    },
    type: function (obj: object) {
      return obj == null ? String(obj) : class2type[Object.prototype.toString.call(obj)] || 'object'
    },
  }
  // The DOM ready check for Internet Explorer
  function doScrollCheck() {
    if (ReadyObj.isReady) {
      return
    }

    try {
      // If IE is used, use the trick by Diego Perini
      // http://javascript.nwbox.com/IEContentLoaded/
      ;(document.documentElement as any).doScroll('left')
    } catch (e) {
      setTimeout(doScrollCheck, 1)
      return
    }

    // and execute any waiting functions
    ReadyObj.ready()
  }
  // Cleanup functions for the document ready method
  if (!!document.addEventListener) {
    DOMContentLoaded = function () {
      document.removeEventListener('DOMContentLoaded', DOMContentLoaded, false)
      ReadyObj.ready()
    }
  } else if (!!(document as any).attachEvent) {
    DOMContentLoaded = function () {
      // Make sure body exists, at least, in case IE gets a little overzealous (ticket #5443).
      if (document.readyState === 'complete') {
        ;(document as any).detachEvent('onreadystatechange', DOMContentLoaded)
        ReadyObj.ready()
      }
    }
  }
  function ready(fn: { (): void }) {
    // Attach the listeners
    ReadyObj.bindReady()

    ReadyObj.type(fn)

    // Add the callback
    readyList.done(fn) //readyList is result of _Deferred()
  }
  return ready
})()

export function nodeToString(node?: HTMLDivElement | null) {
  let tmpNode: HTMLDivElement | null = document.createElement('div') as HTMLDivElement
  if (node) tmpNode.appendChild(node.cloneNode(true))

  const str = tmpNode.innerHTML
  tmpNode = node = null // prevent memory leaks in IE

  return str
}
