import { LOG_LEVEL } from 'src/constants'

import { Environment } from 'src/config'

import { logger, OrderState } from 'src/utils'

export interface ProductDataProps {
  image?: string
  name?: string
}

export interface HistoryDataProps {
  message?: string
  success?: boolean
  error?: {
    code: number
    message: string
  }
}

interface LockerProps {
  download?: string
  license_code?: string
  instructions?: string
}

export interface OrderDataProps {
  success?: boolean
  error?: {
    message: string
  }
  state?: OrderState
  checkout: {
    image_url: string
    title: string
  }
  order: {
    has_locker?: boolean
    order_id: string
    formatted_total: string
    receipt_url: string
    customer: {
      email: string
    }
  }
  lockers?: LockerProps[]
}

// TODO type enum https://developer.paddle.com/guides/ZG9jOjI1MzU0MDUz-displaying-localized-prices
export interface PriceDataProps {
  country?: string
  price?: {
    gross: string
    net: string
    tax: string
    tax_included: boolean
  }
  quantity?: number
  recurring?: {
    price: {
      gross: string
      net: string
      tax: string
      tax_included: boolean
    }
    subscription: {
      trial_days: number
      length: number
      type: string
    }
  }
}

// Can be ProductDataProps | HistoryDataProps | OrderDataProps | PriceDataProps
interface JsonpCallbackProps {
  (data: any): void
}

export function jsonp(url: string, callback: JsonpCallbackProps, context?: object | string) {
  const name = '_jsonp_' + Math.ceil(Math.random() * 10000000)
  if (url.match(/\?/)) {
    url += '&callback=' + name
  } else {
    url += '?callback=' + name
  }

  let script: HTMLScriptElement | null = document.createElement('script') as HTMLScriptElement
  script.type = 'text/javascript'
  script.src = url

  window[name] = function (data: ProductDataProps) {
    logger.log('[PADDLE CLASSIC] Paddle API call finished (' + url + '), response: ' + JSON.stringify(data))
    callback.call(context || window, data)
    document.getElementsByTagName('head')[0].removeChild(script as Node)
    script = null
    delete window[name]
  }

  document.getElementsByTagName('head')[0].appendChild(script)
}

export interface AjaxRequestCallbackProps {
  (data: string): void
}

// Creates and processes a simple XMLHttpRequest
// TODO: ajaxRequest is not used anywhere, we may remove it as part of https://paddle.atlassian.net/browse/CO-2781
export function ajaxRequest(
  url: string,
  method: string,
  data: object,
  callback: AjaxRequestCallbackProps,
): boolean | void {
  if (!window.XMLHttpRequest) {
    return false
  }

  method = method || 'GET'
  data = data || {}
  callback = callback || function () {}

  const httpRequest = new XMLHttpRequest()
  httpRequest.open(method, url, true)
  httpRequest.setRequestHeader('Content-Type', 'application/json')
  httpRequest.onreadystatechange = function () {
    if (httpRequest.readyState === XMLHttpRequest.DONE && httpRequest.status === 200) {
      try {
        callback(httpRequest.responseText)
      } catch (error) {
        logger.log(
          '[PADDLE CLASSIC] There was an error while executing a callback after an AJAX request',
          LOG_LEVEL.WARNING,
        )
      }
    }
  }
  httpRequest.send(JSON.stringify(data))
}

export function post(url: string, parameterString: any, callback: Function) {
  const http = new XMLHttpRequest()
  http.open('POST', url, true)
  http.setRequestHeader('Content-type', 'application/x-www-form-urlencoded')

  http.onreadystatechange = function () {
    if (http.readyState === 4 && http.status === 200) {
      if (typeof callback == 'function') {
        callback(http.responseText)
      }
    }
  }

  http.send(parameterString)
}

interface PricesProps {
  [productId: string]: PriceDataProps
}
const prices: PricesProps = {}

// Calls the checkout public pricing API to get the currency price of the product for the current user.
export function getPrices(productId: string, quantity: number, callback: (arg: PriceDataProps) => void = () => {}) {
  if (typeof prices[productId] == 'undefined' || prices[productId].quantity !== quantity) {
    jsonp(
      Environment.defaults().pricesApi + '?product_id=' + productId + '&quantity=' + quantity,
      _handlePricesApiResponse(productId, callback),
    )
  } else {
    if (typeof callback == 'function') {
      callback(prices[productId])
    }
  }
}

export const _handlePricesApiResponse =
  (productId: string, callback: (arg: PriceDataProps) => void) => (data: PriceDataProps) => {
    prices[productId] = {}
    prices[productId] = data

    if (typeof callback == 'function') {
      callback(prices[productId])
    }
  }
