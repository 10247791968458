import 'core-js/stable'
import { CHECKOUT_METHOD } from 'src/constants'

import { Paddle } from 'src/classes/paddle'

import { CheckoutSuccessProps, CloseCheckoutProps } from 'src/utils/checkout'

import packageInfo from '../package.json'

export interface ActiveCheckout {
  method?: CHECKOUT_METHOD
  product?: string | number
  isUpsell?: boolean
  upsell?: string | number
  frameTarget?: string
  displayModeTheme?: string
  loadCallback?: () => void
  successCallback?: (eventData: CheckoutSuccessProps) => void
  closeCallback?: (eventData: CloseCheckoutProps) => void
  marketingConsent?: string
  email?: string
  country?: string
  postcode?: string
  success?: string
  locale?: string
  parentURL?: string
  parent_url?: string
  guest_email?: string
  guest_country?: string
  guest_postcode?: string
  display_mode?: string
}

declare global {
  interface Window {
    _activeCheckout: ActiveCheckout
    _cfeProps?: {
      useAsWideOverlayExperiment: boolean
      from_top?: string
      cta_background_color?: string
    }
    _hthck: 0 | 1
    paddleAffiliateAnalytics: {
      init: Function
      user: Record<string, any>
    }
    opera: string
    ApplePaySession: {
      canMakePayments: Function
    }
    PaddleWindow?: Window
    PaddleFrame?: HTMLIFrameElement
    checkScrollDepth?: ReturnType<typeof setTimeout>
    PaddleCheckWindowClosure?: number
    UpsellPosition?: number
    upsellType?: string
    checkoutLoaded?: boolean
  }
}

const PaddleInstance = new Paddle(packageInfo.version)
export { PaddleInstance as Paddle, PaddleInstance as PaddleClassic }
