function updatePlatform() {
    var ua = navigator.userAgent;
    var body = document.body;

    // initially mac so that we hide other platform and prevent glitching on load
    body.classList.remove("mac");

    var has_d = false;
    if (window.location.hash == "#mac") {
        document.body.classList.add("d_mac");
        has_d = true;
    } else if (window.location.hash == "#win") {
        document.body.classList.add("d_win");
        has_d = true;
    }

    if (ua.indexOf("Macintosh") != -1) {
        document.body.classList.add("mac");

        if (!has_d)
            document.body.classList.add("d_mac");
    }
    else {
        if (ua.indexOf("WOW64") != -1 ||
            ua.indexOf("Win64") != -1) {
            document.body.classList.add("win64");
        } else {
            document.body.classList.add("win32");
        }
        if (!has_d)
            document.body.classList.add("d_win");
    }
}

updatePlatform();