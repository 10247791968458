export const POPUP_WINDOW = {
  width: 370,
  height: 470,
  location: 'yes',
  menubar: 'no',
  resizable: 'yes',
  scrollbars: 'yes',
  status: 'no',
  toolbar: 'no',
}

export enum POPUP_TYPE {
  POPUP = 'popup',
  AUDIENCE = 'audience',
  DOWNLOAD = 'download',
  ORDER = 'order',
}

export enum POPUP_METHOD {
  MANUAL = 'Manual',
  DOWNLOAD = 'Download',
  ORDER = 'Order',
  EXIT_INTENT = 'ExitIntent',
  SCROLL_DEPTH = 'ScrollDepth',
  TIMED = 'Timed',
}
