import { ActiveCheckout } from 'src'
import { ANALYTICS_EVENT, CHECKOUT_METHOD, DISPLAY_MODE, LOG_LEVEL, THEME } from 'src/constants'

import { Environment, Options, Status } from 'src/config'

import {
  analyticsEvent,
  buildPaddleReferrerString,
  closeCheckout,
  createUpsell,
  hasValue,
  isTruthy,
  jsonp,
  logger,
  ProductDataProps,
  renderCheckoutFrame,
  renderCheckoutWindow,
} from 'src/utils'
import { EventProps } from 'src/utils/analytics'
import { CheckoutSuccessProps, CloseCheckoutProps } from 'src/utils/checkout'

import { isObjectValid } from '../utils/helpers'

export interface CheckoutInputAttributesProps {
  method?: CHECKOUT_METHOD
  locale?: string
  price?: string
  prices?: [
    {
      currency: string
      price: string | number
      auth: string
    },
  ]
  recurringPrices?: [
    {
      currency: string
      price: string | number
      auth: string
    },
  ]
  auth?: string
  title?: string
  internal?: string
  plan?: string
  vendor?: number | null
  quantity?: number
  referring_domain?: string | null
  passthrough?: string | object
  marketingConsent?: '0' | '1'
  checkoutVersion?: string
  email?: string
  country?: string
  postcode?: string
  product: string
  trialDays?: number
  trialDaysAuth?: string
  upsell?: string
  coupon?: string
  success?: string
  upsellCoupon?: string
  upsellPassthrough?: string
  upsellAction?: string
  upsellText?: string
  upsellTitle?: string
  isUpsell?: boolean
  theme?: THEME
  allowQuantity?: boolean | string
  message?: string
  disableLogout?: boolean | string
  displayModeTheme?: string
  display_mode_theme?: string // used by sdk
  frameStyle?: string
  frameTarget?: string
  frameInitialHeight?: number
  override?: string
  loadCallback?: () => void
  eventCallback?: (eventData: EventProps) => void
  successCallback?: (eventData: CheckoutSuccessProps) => void
  closeCallback?: (eventData: CloseCheckoutProps) => void
  customData?: Record<string, string | number | boolean> | string
  parentURL?: string
  parent_url?: string
  is_popup?: string
  paddle_js?: string
  guest_email?: string
  guest_country?: string
  guest_postcode?: string
  popup?: string
  popup_window?: string
  display_mode?: DISPLAY_MODE
  hideTaxLink?: boolean
}
export interface CheckoutOutputAttributesProps {
  locale?: string
  price?: string
  product?: string | number
  title?: string
  quantity?: number
  method?: CHECKOUT_METHOD
  passthrough?: string
  upsell?: string | number
  referring_domain?: string | null
  marketing_consent?: '0' | '1'
  internal?: string
  plan?: string
  vendor?: number | null
  guest_email?: string
  guest_country?: string
  guest_postcode?: string
  display_mode?: DISPLAY_MODE
  trial_days?: number
  auth?: string
  trial_days_auth?: string
  quantity_variable?: string
  custom_message?: string
  disable_logout?: boolean | string
  display_mode_theme?: string
  override?: string
  apple_pay_enabled?: boolean
  popup?: string
  is_popup?: string
  paddle_js?: string
  parent_url?: string
  parentURL?: string
  popup_window?: string
  coupon?: string
  success?: string
  affiliate_success?: string
  upsellCoupon?: string
  upsellPassthrough?: string
  upsellAction?: string
  upsellText?: string
  upsellTitle?: string
  isUpsell?: boolean
  checkout_initiated?: number
  custom_data?: string
  hide_tax_link?: boolean
}

class _Checkout {
  public isOpen: boolean = false
  open(checkoutInputAttributes?: CheckoutInputAttributesProps) {
    closeCheckout({}, false)
    if (!Status.completedSetup) {
      logger.log(
        "[PADDLE CLASSIC] You haven't called Paddle.Setup() - using Paddle.js without calling Paddle.Setup() is unsupported and may result in unexpected behaviour. See: https://developer.paddle.com/guides/how-tos/checkout/paddle-checkout",
        LOG_LEVEL.WARNING,
        true,
      )
    }

    if (typeof checkoutInputAttributes == 'object') {
      // Determine how we should open the checkout
      const checkoutProps: CheckoutOutputAttributesProps = buildCheckoutProps(checkoutInputAttributes)
      // Create Product Upsell **Beta**
      // @note Only show product upsells on overlay checkout.
      if (
        [CHECKOUT_METHOD.INLINE, CHECKOUT_METHOD.OVERLAY, CHECKOUT_METHOD.WIDE_OVERLAY].indexOf(
          checkoutProps.method as CHECKOUT_METHOD,
        ) > -1 &&
        typeof checkoutInputAttributes.upsell != 'undefined' &&
        checkoutInputAttributes.upsell !== ''
      ) {
        checkoutProps.upsell = checkoutInputAttributes.upsell
        jsonp(
          Environment.defaults().dataApi + '?product_id=' + checkoutProps.upsell,
          _handleDataResponse(checkoutInputAttributes, checkoutProps),
        )
      }

      logger.log('[PADDLE CLASSIC] Creating checkout with attributes: ' + JSON.stringify(checkoutProps))

      // Open checkout...
      const frameProps = {
        frameStyle: checkoutInputAttributes.frameStyle,
        frameInitialHeight: checkoutInputAttributes.frameInitialHeight,
        frameTarget: checkoutInputAttributes.frameTarget,
      }
      if (checkoutInputAttributes.method === CHECKOUT_METHOD.WIDE_OVERLAY) {
        checkoutProps.display_mode = DISPLAY_MODE.WIDE_OVERLAY
        renderCheckoutFrame(checkoutInputAttributes.product, frameProps, checkoutProps, false)
      } else if (checkoutInputAttributes.method === CHECKOUT_METHOD.SDK) {
        checkoutProps.display_mode = DISPLAY_MODE.SDK
        renderCheckoutFrame(checkoutInputAttributes.product, frameProps, checkoutProps, false)
      } else if (checkoutInputAttributes.method === CHECKOUT_METHOD.OVERLAY) {
        checkoutProps.display_mode = DISPLAY_MODE.OVERLAY
        renderCheckoutFrame(checkoutInputAttributes.product, frameProps, checkoutProps, false)
      } else if (checkoutInputAttributes.method === CHECKOUT_METHOD.INLINE) {
        checkoutProps.display_mode = DISPLAY_MODE.INLINE
        renderCheckoutFrame(checkoutInputAttributes.product, frameProps, checkoutProps, true)
      } else {
        checkoutProps.display_mode = DISPLAY_MODE.POPUP
        renderCheckoutWindow(checkoutInputAttributes.product, checkoutProps)
      }
      analyticsEvent(ANALYTICS_EVENT.CHECKOUT_OPEN)
    } else {
      throw new Error('[PADDLE CLASSIC] An object of checkout parameters must be passed to Paddle.Checkout.open()')
    }
  }
}

export const buildCheckoutProps = (input: CheckoutInputAttributesProps): CheckoutOutputAttributesProps => {
  const checkoutProps: CheckoutOutputAttributesProps = {}

  if (typeof input.method === 'undefined' || Object.values(CHECKOUT_METHOD).indexOf(input.method) === -1) {
    input.method = CHECKOUT_METHOD.OVERLAY
  }

  checkoutProps.method = input.method
  checkoutProps.product = input.product

  // If 'prices' is specified, convert these to URL params.
  if (typeof input.prices == 'object') {
    input.prices.forEach(function (price) {
      checkoutProps['price_' + price.currency.toLowerCase()] = price.price.toString()
      checkoutProps['price_' + price.currency.toLowerCase() + '_auth'] = price.auth
      if (typeof price.price != 'string') {
        logger.log(
          '[PADDLE CLASSIC] The price override "price" value is specified as a float/integer. It is recommended that you pass prices as strings to ensure the precision of the number is retained when calculating the authentication hash.',
          LOG_LEVEL.WARNING,
          true,
        )
      }
    })
  }
  // If 'recurringPrices' is specified, convert these to URL params.
  if (typeof input.recurringPrices == 'object') {
    input.recurringPrices.forEach(function (price) {
      checkoutProps['recurring_price_' + price.currency.toLowerCase()] = price.price.toString()
      checkoutProps['recurring_price_' + price.currency.toLowerCase() + '_auth'] = price.auth
      if (typeof price.price != 'string') {
        logger.log(
          '[PADDLE CLASSIC] The recurring price override "price" value is specified as a float/integer. It is recommended that you pass prices as strings to ensure the precision of the number is retained when calculating the authentication hash.',
          LOG_LEVEL.WARNING,
          true,
        )
      }
    })
  }
  // Override mobile sessions to use 'window' method.
  // Override SDK sessions to use 'sdk' method.
  if (Options.sdk) {
    input.method = CHECKOUT_METHOD.SDK
    checkoutProps.method = CHECKOUT_METHOD.SDK
  } else {
    // @note Disable overriding of mobile sessions to use overlay (once below implemented).
    // @note Implement: https://github.com/stripe/mobile-viewport-control
    /*
    if(_util.isMobile() && checkoutMethod != 'inline') {
      checkoutAttributes.method = 'window';
      const checkoutMethod = 'window';
    }
    */
  }

  // You can only have one open checkout at a time, we load the active checkout's attributes into the _activeCheckout object for use in other methods
  const activeCheckout = prepareActiveCheckout(input, checkoutProps)
  window._activeCheckout = activeCheckout
  // window._activeCheckout = input

  // If this is an Upsell Checkout track that it has been opened.
  if (window._activeCheckout.isUpsell) {
    // Show the 'Return to Previous Checkout' button...
    setTimeout(function () {
      const original = document.getElementById('paddle_upsell_original')
      if (original) {
        original.setAttribute('style', 'display:block;')
      }
    }, 1850)
  }
  // Build the 'referring_domain' parameter based on our campaign values:
  checkoutProps.referring_domain = buildPaddleReferrerString(input.referring_domain)
  // Convert passthrough to string if object
  if (typeof input.passthrough == 'object') {
    checkoutProps.passthrough = JSON.stringify(input.passthrough)
    input.passthrough = JSON.stringify(input.passthrough)
  }
  // Map 'nicely' name field to their actual names needed for the URL (this way you can pass either the ugly or nice names to the function)
  if (typeof input.marketingConsent != 'undefined') {
    checkoutProps.marketing_consent = isTruthy(input.marketingConsent) ? '1' : '0'
  }
  if (typeof input.internal != 'undefined') {
    checkoutProps.internal = input.internal
  }
  if (typeof input.auth != 'undefined') {
    checkoutProps.auth = input.auth
  }
  if (typeof input.success != 'undefined') {
    checkoutProps.success = input.success
  }
  if (typeof input.price != 'undefined') {
    checkoutProps.price = input.price
  }
  if (typeof input.override != 'undefined') {
    checkoutProps.override = input.override
  }

  if (typeof input.locale != 'undefined') {
    checkoutProps.locale = input.locale
  }
  if (typeof input.email != 'undefined') {
    checkoutProps.guest_email = input.email
  }
  if (typeof input.country != 'undefined') {
    checkoutProps.guest_country = input.country
  }
  if (typeof input.postcode != 'undefined') {
    checkoutProps.guest_postcode = input.postcode
  }
  if (typeof input.trialDays != 'undefined') {
    checkoutProps.trial_days = input.trialDays
  }
  if (typeof input.trialDaysAuth != 'undefined') {
    checkoutProps.trial_days_auth = input.trialDaysAuth
  }
  if (typeof input.allowQuantity != 'undefined') {
    checkoutProps.quantity_variable = isTruthy(input.allowQuantity) ? '1' : '0'
  }
  if (typeof input.title != 'undefined') {
    checkoutProps.title = input.title
  }
  if (typeof input.coupon != 'undefined') {
    checkoutProps.coupon = input.coupon
  }
  if (typeof input.quantity != 'undefined') {
    checkoutProps.quantity = input.quantity
  }
  if (typeof input.plan != 'undefined') {
    checkoutProps.plan = input.plan
  }
  if (typeof input.vendor != 'undefined') {
    checkoutProps.vendor = input.vendor
  }
  if (typeof input.message != 'undefined') {
    checkoutProps.custom_message = input.message
  }
  if (typeof input.passthrough != 'undefined') {
    checkoutProps.passthrough = input.passthrough
  }
  if (typeof input.disableLogout != 'undefined') {
    checkoutProps.disable_logout = input.disableLogout
  }
  if (typeof input.displayModeTheme != 'undefined') {
    checkoutProps.display_mode_theme = input.displayModeTheme
  }
  if (typeof input.display_mode_theme != 'undefined') {
    checkoutProps.display_mode_theme = input.display_mode_theme
  }
  // upsell params
  if (typeof input.isUpsell != 'undefined') {
    checkoutProps.isUpsell = input.isUpsell
  }
  if (typeof input.upsellText != 'undefined') {
    checkoutProps.upsellText = input.upsellText
  }
  if (typeof input.upsellTitle != 'undefined') {
    checkoutProps.upsellTitle = input.upsellTitle
  }
  if (typeof input.upsellAction != 'undefined') {
    checkoutProps.upsellAction = input.upsellAction
  }
  if (typeof input.upsellCoupon != 'undefined') {
    checkoutProps.upsellCoupon = input.upsellCoupon
  }
  if (typeof input.upsellPassthrough != 'undefined') {
    checkoutProps.upsellPassthrough = input.upsellPassthrough
  }
  if (typeof input.hideTaxLink != 'undefined') {
    checkoutProps.hide_tax_link = input.hideTaxLink
  }
  if (typeof input.customData != 'undefined') {
    try {
      // When we pass this value using `data-custom-data` html attribute it will be a string. So we are parsing it to check its validity
      const customData = typeof input.customData === 'string' ? JSON.parse(input.customData) : input.customData
      if (isObjectValid(customData)) {
        checkoutProps.custom_data = JSON.stringify(customData)
      } else {
        throw new Error('Invalid custom data')
      }
    } catch (e) {
      logger.log(
        '[PADDLE CLASSIC] The value set at customData is not a valid object and it will be ignored.',
        LOG_LEVEL.WARNING,
        true,
      )
    }
  }

  return checkoutProps
}

export const prepareActiveCheckout = (
  input: CheckoutInputAttributesProps,
  checkoutProps: CheckoutOutputAttributesProps,
): ActiveCheckout => {
  input.parentURL = checkoutProps.parentURL = window.location.href // @old
  input.parent_url = checkoutProps.parent_url = window.location.href // @new 1070
  return input as ActiveCheckout
}

export const _handleDataResponse =
  (checkoutInputAttributes: CheckoutInputAttributesProps, checkoutOutputAttributes: CheckoutOutputAttributesProps) =>
  (data: ProductDataProps) => {
    const imageUrl = data.image
    const title =
      typeof checkoutInputAttributes.upsellTitle != 'undefined'
        ? checkoutInputAttributes.upsellTitle
        : 'Upgrade to ' + data.name + '!'
    const description =
      typeof checkoutInputAttributes.upsellText != 'undefined'
        ? checkoutInputAttributes.upsellText
        : 'Why not upgrade your purchase to ' + data.name + '?'
    const ctaText =
      typeof checkoutInputAttributes.upsellAction != 'undefined'
        ? checkoutInputAttributes.upsellAction
        : 'Upgrade to ' + data.name + '!'

    const frameProps = {
      frameStyle: checkoutInputAttributes.frameStyle,
      frameInitialHeight: checkoutInputAttributes.frameInitialHeight,
      frameTarget: checkoutInputAttributes.frameTarget,
    }
    const orginalCheckout = function () {
      renderCheckoutFrame(checkoutInputAttributes.product, frameProps, checkoutOutputAttributes, false)
    }
    const passthrough =
      typeof checkoutInputAttributes.upsellPassthrough != 'undefined' &&
      hasValue(checkoutInputAttributes.upsellPassthrough)
        ? checkoutInputAttributes.upsellPassthrough
        : typeof checkoutInputAttributes.passthrough != 'undefined' && hasValue(checkoutInputAttributes.passthrough)
        ? checkoutInputAttributes.passthrough
        : '' || ''
    const upsellCoupon =
      typeof checkoutInputAttributes.upsellCoupon != 'undefined' ? checkoutInputAttributes.upsellCoupon : ''
    const success = typeof checkoutInputAttributes.success != 'undefined' ? checkoutInputAttributes.success : ''
    createUpsell(
      checkoutInputAttributes.upsell,
      imageUrl,
      title,
      description,
      ctaText,
      orginalCheckout,
      passthrough as string,
      upsellCoupon,
      success,
    )
  }

export const Checkout = new _Checkout()
